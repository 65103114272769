<template>
    <Dropdown :active="show" :root="true" :right="right" :top="top" :on_toggle="state => show = state">
        <template slot="btn" slot-scope="{is_active}">
            <button class="briefing_wizard__prompt_button" :class="{
                'briefing_wizard__prompt_button--active': active
            }">
                {{ flavor }} {{ getSelectedLanguageLabel }}
                <i class="fa-regular fa-chevron-down"></i>
            </button>
        </template>
        <input type="text" ref="search" v-model="search" placeholder="Search..." />
        <div class="plan__select_plan__options" style="height: 150px; margin-top:10px; width:100%; overflow-y: scroll;">
            <div class="plan__select_plan__option" :key="`language_${lang.value}`" v-for="lang in filteredLanguages"
                v-on:click="e => select_language(lang)">
                <div class="plan__select_plan__option_group">
                    {{ lang.flag }} {{ lang.label }}
                </div>
            </div>
        </div>
    </Dropdown>
</template>

<script>
import Dropdown from '../components/dropdown.vue'
import { SUPPORTED_LANGUAGES } from './constants'

export default {
    components: { Dropdown },
    props: {
        autofocus: {
            type: Boolean,
            required: false,
            default: true
        },
        on_change: {
            type: Function,
            required: true
        },
        top: {
            type: Number,
            required: false,
            default: 50
        },
        right: {
            type: Number,
            required: false,
            default: 0
        },
        active: {
            type: Boolean,
            required: false,
            default: false
        },
        default_language: {
            type: String,
            required: false,
            default: null
        },
        flavor: {
            type: String,
            required: false,
            default: 'Translate to'
        },
    },
    data() {
        return {
            search: '',
            selected_language: null,
            show: false,
            languages: SUPPORTED_LANGUAGES
        }
    },
    computed: {
        filteredLanguages() {
            if (!this.search) return this.languages;
            const searchLower = this.search.toLowerCase();
            return this.languages.filter(lang =>
                lang.label.toLowerCase().includes(searchLower) ||
                lang.value.toLowerCase().includes(searchLower)
            );
        },
        getSelectedLanguageLabel() {
            if (!this.selected_language) return '';
            const lang = this.languages.find(l => l.value === this.selected_language);
            return lang ? lang.label : this.selected_language;
        }
    },
    mounted() {
        if (this.default_language) {
            this.selected_language = this.default_language;
        }
    },
    watch: {
        show(newVal) {
            if (newVal) {
                this.$nextTick(() => {
                    this.$refs.search.focus();
                });
            }
        }
    },
    methods: {
        select_language(lang) {
            this.selected_language = lang.value;
            this.on_change(lang);
            this.show = false;
        }
    }
}
</script>