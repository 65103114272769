<template>
    <div class="briefing_wizard__upload_instructions__container">
        <h5>Upload instructions (for best results)</h5>
        <div class="briefing_wizard__upload_instructions">
            <div class="briefing_wizard__upload_instruction">
                <img src="/icon-do.svg" class="briefing_wizard__upload_instruction__icon" />
                <div class="briefing_wizard__upload_instruction__visual">
                    <img src="/upload-instruction-image.png" />
                </div>
                <div class="briefing_wizard__upload_instruction__text">
                    <h4>Upload clear images with background</h4>
                    <p>Upload JPEG or PNG files.</p>
                </div>
            </div>

            <div class="briefing_wizard__upload_instruction">
                <img src="/icon-do.svg" class="briefing_wizard__upload_instruction__icon" />
                <div class="briefing_wizard__upload_instruction__visual">
                    <img src="/upload-instruction-image-6.gif" />
                </div>
                <div class="briefing_wizard__upload_instruction__text">
                    <h4>Upload short videos</h4>
                    <p>Upload MP4 or MOV files. Max 250MB or 10 minutes.</p>
                </div>
            </div>

            <div class="briefing_wizard__upload_instruction">
                <img src="/icon-do.svg" class="briefing_wizard__upload_instruction__icon" />
                <div class="briefing_wizard__upload_instruction__visual">
                    <img src="/upload-instruction-image-3.png" />
                </div>
                <div class="briefing_wizard__upload_instruction__text">
                    <h4>Image without background</h4>
                    <p>Upload images with light or transparent background.</p>
                </div>
            </div>

            <div class="briefing_wizard__upload_instruction">
                <img src="/icon-dont.svg" class="briefing_wizard__upload_instruction__icon" />
                <div class="briefing_wizard__upload_instruction__visual">
                    <img src="/upload-instruction-image-2.png" />
                </div>
                <div class="briefing_wizard__upload_instruction__text">
                    <h4>Text/graphics/subtitles overlays</h4>
                    <p>Don't upload assets with text/graphics/subtitle overlays.</p>
                </div>
            </div>

            <div class="briefing_wizard__upload_instruction">
                <img src="/icon-dont.svg" class="briefing_wizard__upload_instruction__icon" />
                <div class="briefing_wizard__upload_instruction__visual">
                    <img src="/upload-instruction-image-5.gif" />
                </div>
                <div class="briefing_wizard__upload_instruction__text">
                    <h4>Don't use video with voice-over</h4>
                    <p>The audio of your video will not be used.</p>
                </div>
            </div>

            <div class="briefing_wizard__upload_instruction">
                <img src="/icon-dont.svg" class="briefing_wizard__upload_instruction__icon" />
                <div class="briefing_wizard__upload_instruction__visual">
                    <img src="/upload-instruction-image-4.png" />
                </div>
                <div class="briefing_wizard__upload_instruction__text">
                    <h4>Don't upload brand logos or graphics</h4>
                    <p>Use brandkit under settings, to add brand colours and logos.</p>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'UploadInstructions'
}
</script>