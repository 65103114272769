<template>
    <div :style="{ ...layer.h_align(), color: layer.color, width: layer.width + 'px'}" style="display: inline-flex; flex-direction: column; gap: 30px;">
        <div>
            <div style="display: flex; gap: 10px;" :style="{fontSize: layer.fontSize * 0.36 + 'px', color: layer.config.review_card_3.stars_color}">
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
                <i class="fa-solid fa-star"></i>
            </div>
        </div>
        <div class="">
            <div 
                class="works_title" 
                :style="`
                    color: ${layer.color}; 
                    font-size: ${layer.fontSize * .45}px; 
                    background: ${bgColor}; 
                    font-family: ${font};
                `"
                style="font-weight: bold;"
                v-html="parsedText"
            ></div>
            <svg 
                style="visibility: hidden; position: absolute;" 
                width="0" 
                height="0" 
                xmlns="http://www.w3.org/2000/svg" 
                version="1.1"
            >
                <defs>
                    <filter id="instagram">
                        <feGaussianBlur in="SourceGraphic" stdDeviation="5" result="blur" />    
                        <feColorMatrix 
                            in="blur" 
                            mode="matrix" 
                            values="1 0 0 0 0  
                                    0 1 0 0 0  
                                    0 0 1 0 0  
                                    0 0 0 20 -8" 
                            result="goo" 
                        />
                        <feComposite in="SourceGraphic" in2="goo" operator="atop"/>
                    </filter>
                </defs>
            </svg>
        </div>

        <!-- Gebruik nu v-html="parsedReviewName" zodat de parser-logica wordt toegepast -->
        <div class="">
            <div
                class="works_title"
                :style="`
                    color: ${layer.color}; 
                    font-size: ${layer.fontSize * .45}px; 
                    background: ${bgColor}; 
                    font-family: ${font};
                `"
                v-html="parsedReviewName"
            ></div>
        </div>
    </div>
</template>

<script>
import { highlight } from '../../../lib/parsers'
import FontMixin from '../mixins/font'

export default {
    props: ['layer', 'animation_uid'],
    mixins: [FontMixin],

    computed: {
        component_name() {
            return this.layer.config.component.type
        },
        line_length() {
            return this.layer.config[this.component_name].line_length || 60
        },
        bgColor() {
            return this.layer.config[this.component_name].bg_color || 'white'
        },

        /**
         * Verwerkt de hoofd-tekst:
         * - Onderlijnt alles wat na '@' komt
         * - Past highlight toe
         */
        parsedText() {
            let text = this.layer.text || ''
            text = text.replace(/@(\S+)/g, '@<span style="text-decoration: underline;">$1</span>')

            text = highlight.highlight(
                text,
                this.layer.config?.highlights?.background_color,
                this.layer.config?.highlights?.text_color
            )
            return text
        },

        /**
         * Haalt de 'review_name' op en verwerkt hem net zoals `parsedText`
         */
        parsedReviewName() {
            let name = this.layer.config[this.component_name].review_name || ''

            // Als je ook '@...' wilt onderlijnen:
            name = name.replace(/@(\S+)/g, '@<span style="text-decoration: underline;">$1</span>')

            // Eventueel highlighten
            name = highlight.highlight(
                name,
                this.layer.config?.highlights?.background_color,
                this.layer.config?.highlights?.text_color
            )

            return name
        }
    },

    methods: {
        parse(text) {
            return highlight.highlight(
                text,
                this.layer.config?.highlights?.background_color,
                this.layer.config?.highlights?.text_color
            )
        },
    }
}
</script>