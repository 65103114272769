import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class Callout extends Component {
  constructor({ opts } = { opts: {} }) {
    super()
    this.opts = opts
  }

  name() {
    return 'Callout'
  }

  labels() {
    return ['callout']
  }

  icon() {
    return 'fa-brands fa-line'
  }

  configFields() {
    return {
      name: this.name(),
      options: [
        {
          type: 'textArea',
          name: 'Callout',
          hide_label: true,
          key: 'root.text',
        },
        [
          {
            type: 'color',
            name: 'Text color',
            key: 'root.color',
          },
          {
            type: 'color',
            name: 'Line color',
            key: 'line_color',
          },
        ],
        {
          type: 'buttonGroup',
          name: 'Alignment',
          hide_label: false,
          key: 'align',
          options: [
            {
              type: 'icon',
              name: 'Left',
              icon: 'fa-regular fa-arrow-left',
              value: 'left',
            },
            {
              type: 'icon',
              name: 'Right',
              icon: 'fa-regular fa-arrow-right',
              value: 'right',
            },
            {
              type: 'icon',
              name: 'Left',
              icon: 'fa-regular fa-arrow-up',
              value: 'top',
            },
            {
              type: 'icon',
              name: 'Right',
              icon: 'fa-regular fa-arrow-down',
              value: 'bottom',
            }
          ]
        },
        {
          type: 'buttonGroup',
          name: 'Line',
          hide_label: false,
          key: 'line_align',
          options: [
            {
              type: 'text',
              name: 'Normal',
              icon: 'fa-regular fa-arrow-left',
              value: 'normal',
            },
            {
              type: 'text',
              name: 'Invert',
              icon: 'fa-regular fa-arrow-right',
              value: 'invert',
            },
          ]
        },
        {
          type: 'toggle',
          name: 'Show bullet',
          key: 'show_bullet',
        },
        {
          type: 'slider',
          name: 'Text width',
          key: 'line_length',
          min: 20,
          max: 80,
          step: 1,
        },
      ],
    }
  }

  variants() {
    const baseConfig = this.baseConfig()

    return [
      {
        background: '#E1E3E5',
        layers: [
          {
            ...baseConfig,
            color: 'white',
            config: {
              ...baseConfig.config,
              callout: {
                ...baseConfig.config.callout,
                align: 'left',
                line_length: 80,
                line_color: 'white',
                show_bullet: 'true',
              }
            }
          }
        ]
      },
    ]
  }

  baseConfig() {
    return {
      layer_type: 'component',
      color: 'black',
      background: 'transparent',
      text: 'Sample callout',
      width: 300,
      height: 200,
      fontSize: 40,
      ...this.opts,
      config: {
        v_align: 'none',
        show_background: false,
        component: {
          type: 'callout',
          name: 'Callout',
        },
        callout: {
          align: 'left',
          line_align: 'normal',
          line_length: 80,
          line_color: 'black',
          show_bullet: 'true',
        }
      }
    }
  }

  config() {
    return {
      background: '#E1E3E5',
      layers: [
        Layer.factory(this.baseConfig())
      ]
    }
  }
}
