<template>
    <div class="briefing_wizard__flow_selector">
        <h3>How would you like to create your ads?</h3>

        <div class="briefing_wizard__flow_options">
            <div class="briefing_wizard__flow_option" @click="$emit('flow-selected', 'UPLOAD')">
                <div class="briefing_wizard__flow_option__icon">
                    <i class="fa-regular fa-images"></i>
                </div>
                <div class="briefing_wizard__flow_option__content">
                    <h4>Upload or select media</h4>
                    <p>Upload new files or choose from your existing media library</p>
                    <ul>
                        <li><i class="fa-regular fa-check"></i> Upload new images & videos</li>
                        <li><i class="fa-regular fa-check"></i> Select from media library</li>
                        <li><i class="fa-regular fa-check"></i> Supports JPG, PNG, MP4</li>
                    </ul>
                </div>
            </div>

            <div class="briefing_wizard__flow_option" @click="$emit('flow-selected', 'FROM_UGC_VIDEO')">
                <div class="briefing_wizard__flow_option__icon">
                    <i class="fa-regular fa-video"></i>
                </div>
                <div class="briefing_wizard__flow_option__content">
                    <h4>Extract from video</h4>
                    <p>Select key moments from your video to create multiple ads</p>
                    <ul>
                        <li><i class="fa-regular fa-check"></i> Extract multiple scenes</li>
                        <li><i class="fa-regular fa-check"></i> Perfect for UGC content</li>
                        <li><i class="fa-regular fa-check"></i> Automatic scene detection</li>
                    </ul>
                </div>
            </div>

            <div class="briefing_wizard__flow_option" @click="$emit('flow-selected', 'FROM_WEBSITE')">
                <div class="briefing_wizard__flow_option__icon">
                    <i class="fa-regular fa-globe"></i>
                </div>
                <div class="briefing_wizard__flow_option__content">
                    <h4>Analyze Website</h4>
                    <p>Fetch content directly from a specified website</p>
                    <ul>
                        <li><i class="fa-regular fa-check"></i> Enter website URL</li>
                        <li><i class="fa-regular fa-check"></i> Automatic content extraction</li>
                        <li><i class="fa-regular fa-check"></i> Supports HTML, images, and text</li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: 'FlowSelector',
    emits: ['flow-selected']
}
</script>