import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class ImageStacker extends Component {
  constructor({ opts } = { opts: {} }) {
    super()
    this.opts = opts
  }

  name() {
    return 'Image stacker'
  }

  labels() {
    return ['stack']
  }

  icon() {
    return 'fa-solid fa-heart'
  }

  sizeConfig(size) {
    if (size) {
      switch (size.aspect_ratio) {
        case '1:1':
          return {
            config: {
              image_stacker: {

              }
            }
          }
        case '16:9':
          return {
            config: {
              image_stacker: {

              }
            }
          }
        case '9:16':
          return {
            config: {
              image_stacker: {

              }
            }
          }
        default:
          return {}
      }
    }
    return {}
  }

  configFields() {
    return {
      name: this.name(),
      options: [
        {
          type: 'slider',
          name: 'Number of images',
          key: 'number_of_images',
          min: 2,
          max: 8,
          step: 1,
          transformers: [
            (key, value, layer) => {
              const l = { ...layer }
              for (let i = 0; i < value; i += 1) {
                const k = i.toString()
                if (!l.config.image_stacker.images[k]) {
                  l.config.image_stacker.images[k] = l.config.image_stacker.default_image
                }
              }
              return l
            }
          ]
        },
        {
          type: 'multiAssetPicker',
          name: 'Images',
          key: 'images',
          number_of_assets_key: 'number_of_images',
        },
        {
          type: 'buttonGroup',
          name: 'Layout',
          hide_label: false,
          key: 'layout',
          options: [
            {
              type: 'text',
              name: 'Line',
              value: 'line',
            },
            {
              type: 'text',
              name: 'Podium',
              value: 'podium',
            },
          ]
        },
        {
          type: 'slider',
          name: 'Image size',
          key: 'image_scale',
          min: 1,
          max: 800,
          step: 1,
        },
        {
          type: 'slider',
          name: 'Gap',
          key: 'gap',
          min: 0,
          max: 1000,
          step: 1,
        },
        {
          type: 'slider',
          name: 'Direction',
          key: 'direction',
          min: 0,
          max: 360,
          step: 1,
        },
      ],
    }
  }

  variants() {
    const baseConfig = this.baseConfig()

    return [
      {
        background: '#E1E3E5',
        layers: [{
          ...baseConfig,
          config: {
            ...baseConfig.config,
            image_stacker: {
              ...baseConfig.config.image_stacker,
              layout: 'podium'
            }
          },
        }]
      },
    ]
  }

  baseConfig() {
    return {
      layer_type: 'component',
      width: 1046,
      height: 460,
      ...this.opts,
      unlinked_properties: ['component.*'],
      variable_tag: 'product',
      config: {
        component: {
          type: 'image_stacker',
          name: 'Image stack',
        },
        image_stacker: {
          color_1: '#F3F3F3',
          image_scale: 290,
          gap: 100,
          default_image: 'https://cdn.adflow.io/public/9bef15f1-17c5-4de2-8cf8-9291f21b4062.png',
          images: {
            0: 'https://cdn.adflow.io/public/9bef15f1-17c5-4de2-8cf8-9291f21b4062.png',
            1: 'https://cdn.adflow.io/public/9bef15f1-17c5-4de2-8cf8-9291f21b4062.png',
            2: 'https://cdn.adflow.io/public/9bef15f1-17c5-4de2-8cf8-9291f21b4062.png',
            3: 'https://cdn.adflow.io/public/9bef15f1-17c5-4de2-8cf8-9291f21b4062.png',
            4: 'https://cdn.adflow.io/public/9bef15f1-17c5-4de2-8cf8-9291f21b4062.png',
          },
          scale: 'contain',
          number_of_images: 5,
          border_radius: 0,
          direction: 0,
          layout: 'line'
        }
      }
    }
  }

  config() {
    return {
      background: '#E1E3E5',
      layers: [
        Layer.factory(this.baseConfig())
      ]
    }
  }
}
