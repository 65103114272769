<template>
  <div :style="{ color: layer.color, width: layer.width + 'px', position: 'relative' }">

    <svg 
      xmlns="http://www.w3.org/2000/svg"
      :width="layer.width" 
      :height="layer.height"
      fill="none"
      viewBox="0 0 1152 1007.999987"
      style="
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: -1;
      "
    >
      <defs>
        <!-- Alleen nodig als je clipPath-elementen hebt -->
        <clipPath v-if="shapePaths.clipPath" :id="clipId">
          <path :d="shapePaths.clipPath" />
        </clipPath>
      </defs>

      <g v-if="shapePaths.main" :clip-path="clipPathUrl">
        <path
          :fill="layer.config.sticker_arrows.line_color"
          :fill-opacity="layer.config.sticker_arrows.line_color ? 1 : 0"
          :transform="combinedTransform"
          :d="shapePaths.main"
        />
      </g>
    </svg>

  </div>
</template>

<script>
export default {
  props: ['layer'],
  computed: {
    // Optioneel, maar handig: een unieke ID voor de clipPath,
    // zodat als je meerdere componenten rendert, hun IDs niet botsen.
    clipId() {
      return 'clip-' + this._uid; 
    },
    clipPathUrl() {
      // Als shapePaths.clipPath bestaat, dan is het "url(#clip-1234)"
      // Anders is het leeg en wordt de <g> niet geclipped
      return this.shapePaths.clipPath ? `url(#${this.clipId})` : null;
    },

    // Hier splitsen we de shape "paths" op in: clipPath en main
    shapePaths() {
      switch (this.layer.config.sticker_arrows.shape) {
        case 'bend_bold':
          return {
            // Geen clipPath nodig voor 'bend_bold' in dit voorbeeld
            clipPath: null, 
            main: `M 404.742188 594.488281 C 410.824219 587.613281 415.613281 581.53125 421.078125 576.125 C 439.316406 558.191406 456.941406 539.523438 476.46875 523.0625 C 497.53125 505.3125 527.808594 510.105469 547.214844 530.679688 C 552.0625 535.777344 560.601562 537.375 568.277344 540.933594 C 571.59375 549.164062 563.730469 552.789062 558.878906 557.394531 C 514.851562 599.21875 480.644531 648.597656 450.984375 700.984375 C 439.625 721.007812 432.007812 743.238281 423.414062 764.734375 C 416.65625 781.625 415.796875 784.019531 397.375 782.546875 C 364.519531 779.90625 335.90625 768.726562 315.210938 740.476562 C 279.164062 691.40625 240.289062 644.359375 213.269531 589.269531 C 208.910156 580.304688 205.65625 570.785156 199.636719 556.042969 C 226.597656 560.894531 248.765625 560.710938 263.011719 582.144531 C 271.730469 595.289062 280.636719 608.246094 292.671875 626.179688 C 295.742188 607.324219 297.644531 594.304688 299.980469 581.410156 C 317.605469 484.1875 356.722656 396.484375 430.105469 329.664062 C 496.859375 268.800781 575.277344 226.730469 669.292969 225.5625 C 748.265625 224.644531 816.0625 255.597656 875.320312 305.34375 C 899.027344 325.304688 919.105469 349.6875 940.167969 372.65625 C 945.636719 378.613281 948.644531 386.71875 952.757812 393.90625 C 951.164062 395.441406 949.566406 396.976562 947.96875 398.511719 C 938.265625 392.492188 928.195312 386.964844 919.046875 380.210938 C 913.335938 375.972656 908.914062 369.953125 904.121094 364.546875 C 842.714844 295.085938 765.152344 276.539062 677.277344 295.457031 C 610.15625 309.886719 554.703125 346.0625 508.09375 395.625 C 458.84375 447.890625 425.4375 509.429688 405.417969 578.335938 C 404.5 581.410156 403.761719 584.539062 403.269531 587.671875 C 403.148438 589.085938 403.882812 590.621094 404.742188 594.488281 Z M 404.742188 594.488281 `
          };

        case 'bend_semibold':
          // Dit is jouw voorbeeld-SVG met twee delen: clipPath en het hoofdpad
          return {
            clipPath: `M 148 349.542969 L 1005 349.542969 L 1005 658.542969 L 148 658.542969 Z M 148 349.542969 " clip-rule="nonzero"/></clipPath></defs><g clip-path="url(#9dd4a3985e)"><path fill="#000000" d="M 158.191406 620.550781 C 145.03125 611.613281 147.828125 601.039062 151.371094 590.945312 C 161.054688 562.976562 175.371094 537.808594 197.191406 517.207031 C 266.738281 451.65625 349.105469 409.09375 440.949219 385.492188 C 535.109375 361.277344 628.046875 366.597656 717.910156 405.273438 C 760.183594 423.484375 801.503906 444.015625 844.734375 464.207031 C 843.164062 459.5 841.734375 453.5 839.277344 447.835938 C 822.847656 409.296875 824.277344 400.222656 853.664062 369.800781 C 860.210938 362.980469 867.984375 357.386719 877.121094 349.542969 C 882.03125 357.457031 885.710938 363.050781 889.054688 368.847656 C 925.121094 431.125 960.101562 494.085938 997.941406 555.339844 C 1007.078125 570.207031 1006.261719 581.667969 1000.261719 594.289062 C 988.125 619.664062 966.78125 637.808594 944.554688 653.425781 C 936.78125 658.882812 922.257812 657.109375 911.28125 655.542969 C 830.890625 643.742188 750.164062 639.445312 669.226562 645.925781 C 644 647.96875 619.113281 653.835938 591.839844 658.269531 C 588.839844 650.222656 593.816406 641.285156 598.589844 633.714844 C 621.023438 598.175781 648.296875 568.570312 693.023438 562.296875 C 714.160156 559.363281 735.230469 556.226562 756.367188 553.496094 C 765.777344 552.269531 775.324219 551.996094 786.980469 551.109375 C 743.820312 504.179688 554.132812 459.226562 457.925781 471.640625 C 340.921875 486.71875 241.648438 538.558594 158.191406 620.550781 Z M 158.191406 620.550781 `,
            main: `M 158.191406 620.550781 C 145.03125 611.613281 147.828125 601.039062 151.371094 590.945312 C 161.054688 562.976562 175.371094 537.808594 197.191406 517.207031 C 266.738281 451.65625 349.105469 409.09375 440.949219 385.492188 C 535.109375 361.277344 628.046875 366.597656 717.910156 405.273438 C 760.183594 423.484375 801.503906 444.015625 844.734375 464.207031 C 843.164062 459.5 841.734375 453.5 839.277344 447.835938 C 822.847656 409.296875 824.277344 400.222656 853.664062 369.800781 C 860.210938 362.980469 867.984375 357.386719 877.121094 349.542969 C 882.03125 357.457031 885.710938 363.050781 889.054688 368.847656 C 925.121094 431.125 960.101562 494.085938 997.941406 555.339844 C 1007.078125 570.207031 1006.261719 581.667969 1000.261719 594.289062 C 988.125 619.664062 966.78125 637.808594 944.554688 653.425781 C 936.78125 658.882812 922.257812 657.109375 911.28125 655.542969 C 830.890625 643.742188 750.164062 639.445312 669.226562 645.925781 C 644 647.96875 619.113281 653.835938 591.839844 658.269531 C 588.839844 650.222656 593.816406 641.285156 598.589844 633.714844 C 621.023438 598.175781 648.296875 568.570312 693.023438 562.296875 C 714.160156 559.363281 735.230469 556.226562 756.367188 553.496094 C 765.777344 552.269531 775.324219 551.996094 786.980469 551.109375 C 743.820312 504.179688 554.132812 459.226562 457.925781 471.640625 C 340.921875 486.71875 241.648438 538.558594 158.191406 620.550781 Z M 158.191406 620.550781 `
          };

        default:
          return {
            clipPath: null,
            main: ""
          };
      }
    },

    /** 
     * Dit is de bestaande transform die je al per shape kunt aanpassen.
     * (Bijvoorbeeld om te schalen of te verplaatsen.)
     */
     baseTransform() {
      switch (this.layer.config.sticker_arrows.shape) {
        case "bend_bold":
          return "translate(50,50)";
        case "bend_semibold":
          return "";
        default:
          return "";
      }
    },

    /**
     * Hier voegen we de spiegeling toe als mirror = true.
     * scale(-1,1) spiegelt horizontaal.
     */
     mirrorTransform() {
      const mirror = this.layer.config.sticker_arrows.mirror;
      if (!mirror) {
        return "";
      }
      // Verticaal flip
      return "scale(1, -1) translate(0, -1000)";
    },

    /**
     * We combineren baseTransform + mirrorTransform in één string.
     * De volgorde is vaak:
     *   1) mirror (scale + translate)
     *   2) baseTransform
     * Zodat eerst gespiegeld wordt, dan nog evt. verschoven/geschaald.
     */
    combinedTransform() {
      // Let op de spaties tussen de onderdelen
      return [this.mirrorTransform, this.baseTransform].join(" ");
    }
  }
}
</script>