import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class ReviewCard extends Component {
  constructor({ opts } = { opts: {} }) {
    super()
    this.opts = opts
  }

  name() {
    return 'Review card'
  }

  labels() {
    return ['review']
  }

  icon() {
    return 'fa-solid fa-heart'
  }

  configFields() {
    return {
      name: this.name(),
      options: [
        {
          type: 'assetPicker',
          name: 'Image',
          key: 'image',
        },
        {
          type: 'textArea',
          name: 'Review',
          key: 'content',
        },
        {
          type: 'text',
          name: 'Title',
          key: 'title',
        },
        {
          type: 'text',
          name: 'Subtitle',
          key: 'subtitle',
        }
      ],
    }
  }

  variants() {
    const baseConfig = this.baseConfig()

    return [
      {
        background: 'white',
        layers: [{
          ...baseConfig,
          color: '#F6F6F6',
          background: '#313131',
          fontSize: 30,
          config: {
            ...baseConfig.config,
            review_card: {
              ...baseConfig.config.review_card,
              icon_position: 'l',
              orientation: 'vertical',
              vertical_gap: 10,
              horizontal_gap: 10,
              content: 'A very intuitive tool with many different features. Works well and has excellent customer service.',
              title: 'James Smith',
              subtitle: '21-10-2024',
              rating: 5,
              image: 'https://cdn.adflow.io/public/d7c0cf54-fdef-4e93-bd96-89d783427128.png',
            }
          },
        }]
      },
    ]
  }

  baseConfig() {
    return {
      layer_type: 'component',
      color: 'black',
      align: 'left',
      background: '#FFFFFF',
      width: 560,
      height: 245,
      fontSize: 40,
      ...this.opts,
      config: {
        show_background: true,
        border_radius: 15,
        component: {
          type: 'review_card',
          name: 'Review card',
        },
        review_card: {
          icon_position: 'l',
          orientation: 'vertical',
          vertical_gap: 10,
          horizontal_gap: 10,
          content: 'A very intuitive tool with many different features. Works well and has excellent customer service.',
          title: 'James Smith',
          subtitle: '21-10-2024',
          rating: 5,
          image: 'https://cdn.adflow.io/public/d7c0cf54-fdef-4e93-bd96-89d783427128.png',
        }
      }
    }
  }

  config() {
    return {
      background: '#E1E3E5',
      layers: [
        Layer.factory(this.baseConfig())
      ]
    }
  }
}
