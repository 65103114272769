<template>
    <div class="website-feed">
        <div class="website-feed__header">
            <h3 class="website-feed__title">Analyze Website</h3>
            <p class="website-feed__subtitle">Import images and content directly from any website</p>
        </div>

        <div class="website-feed__form">
            <div class="website-feed__form-group">
                <label for="website-url" class="website-feed__label">URL</label>
                <div class="website-feed__input-group">
                    <div class="website-feed__input-wrapper">
                        <input type="text" id="website-url" v-model="url" class="website-feed__input"
                            :class="{ 'website-feed__input--error': errorMessage }"
                            placeholder="Enter website URL (e.g. https://example.com)" :disabled="isCrawling" />
                    </div>
                    <button v-if="brand_homepage" @click="useHomepage" type="button"
                        class="website-feed__button website-feed__button--secondary">
                        Use Brand Homepage
                    </button>
                    <button @click="startCrawl" :disabled="isCrawling || !url" class="website-feed__button"
                        :class="{ 'website-feed__button--processing': isCrawling }">
                        <i class="fa-regular" :class="isCrawling ? 'fa-spinner fa-spin' : 'fa-arrow-right'"></i>
                        {{ isCrawling ? 'Processing...' : 'Analyze' }}
                    </button>
                </div>
                <span v-if="errorMessage" class="website-feed__error">
                    <i class="fa-regular fa-exclamation-circle"></i>
                    {{ errorMessage }}
                </span>
            </div>

            <transition name="website-feed__fade">
                <div v-if="isCrawling" class="website-feed__status">
                    <div class="website-feed__steps">
                        <div class="website-feed__step" :class="{
                            'website-feed__step--active': currentStep >= 1,
                            'website-feed__step--complete': currentStep > 1
                        }">
                            <div class="website-feed__step-icon">
                                <i class="fa-regular" :class="getStepIcon(1)"></i>
                            </div>
                            <span class="website-feed__step-label">Connecting</span>
                        </div>
                        <div class="website-feed__step" :class="{
                            'website-feed__step--active': currentStep >= 2,
                            'website-feed__step--complete': currentStep > 2
                        }">
                            <div class="website-feed__step-icon">
                                <i class="fa-regular" :class="getStepIcon(2)"></i>
                            </div>
                            <span class="website-feed__step-label">Extracting Content</span>
                        </div>
                        <div class="website-feed__step" :class="{
                            'website-feed__step--active': currentStep >= 3,
                            'website-feed__step--complete': currentStep > 3
                        }">
                            <div class="website-feed__step-icon">
                                <i class="fa-regular" :class="getStepIcon(3)"></i>
                            </div>
                            <span class="website-feed__step-label">Processing</span>
                        </div>
                    </div>
                    <div class="website-feed__message">
                        <i class="fa-regular fa-info-circle"></i>
                        <span class="website-feed__message-text">{{ statusMessage }}</span>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
import api from '../../studio/api';
import consumer from '../../channels/consumer';

export default {
    name: 'WebsiteFeed',
    props: {
        campaignBriefingId: {
            type: Number,
            required: true
        },
        brand_homepage: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            url: '',
            isCrawling: false,
            statusMessage: '',
            errorMessage: '',
            currentStep: 0
        };
    },
    methods: {
        useHomepage() {
            this.url = this.brand_homepage;
        },
        getStepIcon(step) {
            if (this.currentStep > step) return 'fa-check';
            if (this.currentStep === step) return 'fa-spinner fa-spin';
            return 'fa-circle';
        },
        async startCrawl() {
            if (!this.url) {
                this.errorMessage = 'Please enter a valid URL.';
                return;
            }

            this.isCrawling = true;
            this.errorMessage = '';
            this.currentStep = 1;
            this.statusMessage = 'Connecting to website...';

            this.$emit('state-change', { status: 'initializing', message: this.statusMessage });

            try {
                await api.extract_images({ url: this.url, campaign_briefing: { id: this.campaignBriefingId } });
                this.currentStep = 2;
                this.statusMessage = 'Starting content extraction...';
                this.$emit('state-change', { status: 'started', message: this.statusMessage });
                this.setupWebSocket();
            } catch (error) {
                this.errorMessage = 'Failed to connect to website. Please check the URL and try again.';
                this.isCrawling = false;
                this.currentStep = 0;
                this.$emit('state-change', { status: 'failed', message: this.errorMessage });
            }
        },
        setupWebSocket() {
            let obj = null
            consumer.subscriptions.create(
                { channel: 'CampaignBriefingChannel', id: this.campaignBriefingId },
                {
                    received: (data) => {
                        if (data.event === 'extraction') {
                            switch (data.extracting_state) {
                                case 'started':
                                    this.currentStep = 2;
                                    this.statusMessage = 'Extracting content from website...';
                                    break;
                                case 'processing':
                                    this.currentStep = 3;
                                    this.statusMessage = 'Processing extracted content...';
                                    break;
                                case 'analyzing':
                                    this.statusMessage = 'Analyzing content...';
                                    break;
                                case 'asset':
                                    obj = data.asset
                                    break;
                                case 'finished':
                                    this.statusMessage = 'Import completed successfully!';
                                    this.isCrawling = false;
                                    this.currentStep = 4;
                                    setTimeout(() => {
                                        this.currentStep = 0;
                                    }, 2000);
                                    break;
                                case 'failed':
                                    this.errorMessage = 'Import failed. Please try again.';
                                    this.isCrawling = false;
                                    this.currentStep = 0;
                                    break;
                            }
                            this.$emit('state-change', {
                                status: data.extracting_state,
                                message: this.statusMessage,
                                object: obj
                            });
                        }
                    }
                }
            );
        }
    }
};
</script>