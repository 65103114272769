<template>
    <div :style="containerStyle">
        <div>
            <span :style="textStyle" v-html="parsedText"></span>
        </div>
    </div>
</template>

<script>
import { highlight } from '../../../lib/parsers'
import FontMixin from '../mixins/font'

export default {
    props: ['layer', 'animation_uid'],
    mixins: [FontMixin],
    computed: {
        component_name() {
            return this.layer.config.component.type
        },
        line_length() {
            return this.layer.config[this.component_name].line_length || 60
        },
        bgColor() {
            return this.layer.config[this.component_name].bg_color || 'white'
        },
        containerStyle() {
            return {
                color: this.layer.color,
                // Breedte evt. laten staan als gewenst. 
                width: this.layer.width + 'px',
                whiteSpace: 'pre-wrap',     // Zorgt ervoor dat line breaks behouden worden
                wordBreak: 'break-word',
                textAlign: this.layer.align, // Uitlijning van de tekst in het omringende element
            }
        },
        textStyle() {
            // Bouw het style attribuut op
            let style = `color: ${this.layer.color}; font-size: ${this.layer.fontSize * 0.45}px; font-family: ${this.font}; display: inline-block;`
            
            // Alleen achtergrond tonen als show_bg true is
            if (this.layer.config[this.component_name].show_bg) {
                style += ` background: ${this.bgColor}; padding: 10px;` 
            }

            return style
        },
        parsedText() {
            let text = this.layer.text || ''
            
            // Alleen het stuk na @ onderlijnen
            text = text.replace(/@(\S+)/g, '@<span style="text-decoration: underline;">$1</span>')
            
            return text
        }
    },
    methods: {
        parse(text) {
            return highlight.highlight(
                text,
                this.layer.config?.highlights?.background_color,
                this.layer.config?.highlights?.text_color
            )
        },
    }
}
</script>