<template>
  <div class="video-extractor__content">
    <div class="video-extractor__video-player" @keydown="handleKeyDown" tabindex="0">
      <div class="video-extractor__video-container">
        <video ref="video" class="video-extractor__video" crossorigin="anonymous" @timeupdate="updateProgress"
          @loadedmetadata="initializeMarkers" :src="videoSrc" :controls="false"></video>
        <button class="video-extractor__camera-button" @click="captureFrame"
          :class="{ 'video-extractor__camera-button--active': isCapturing }">
          📸
        </button>
        <div v-if="isFlashing" class="video-extractor__flash-overlay"></div>
      </div>
      <div class="video-extractor__controls">
        <button class="video-extractor__control-button" @click="stepFrame(-1)" aria-label="Step Backward">&lt;</button>
        <div class="video-extractor__progress-bar" @mousedown="startDragging" @mousemove="dragProgress"
          @mouseup="stopDragging" @mouseleave="stopDragging">
          <div class="video-extractor__progress" :style="{ width: progressPercentage + '%' }"></div>
          <div v-for="marker in markers" :key="marker.time" class="video-extractor__marker"
            :style="{ left: Math.min(Math.max((marker.time / videoDuration) * 100, 0), 100) + '%' }"
            @mouseenter="showPreview(marker)" @mouseleave="hidePreview">
            <div v-if="hoveredMarker === marker" class="video-extractor__preview"
              :style="{ backgroundImage: `url(${marker.thumbnail})` }">
            </div>
          </div>
        </div>
        <button class="video-extractor__control-button" @click="stepFrame(1)" aria-label="Step Forward">&gt;</button>
      </div>
    </div>
  </div>
</template>

<script>
import MarkerList from "./marker_list.vue";
export default {
  name: "VideoPlayerWithMarkers",
  props: {
    videoSrc: {
      type: String,
      default: "https://adflow-production.s3.eu-central-1.amazonaws.com/public/3e566498-2a03-4015-9abe-8a5f2a4ae913.mp4"
    },
    markersData: {
      type: Array,
      default: () => [],
    },
    onMarkersChange: {
      type: Function,
      default: () => { },
    },
  },
  components: {
    MarkerList,
  },
  watch: {
    markers: {
      handler(markers) {
        this.onMarkersChange(markers);
      },
      deep: true,
    },
  },
  data() {
    return {
      videoDuration: 0,
      progressPercentage: 0,
      markers: [],
      hoveredMarker: null,
      isDragging: false,
      isCapturing: false,
      isFlashing: false,
    };
  },
  methods: {
    removeMarker(marker, index) {
      this.markers.splice(index, 1);
    },
    initializeMarkers() {
      this.videoDuration = this.$refs.video.duration;
      this.markers = this.markersData.map((marker) => ({
        ...marker,
        thumbnail: marker.thumbnail,
      }));
    },
    updateProgress() {
      const video = this.$refs.video;
      this.progressPercentage = (video.currentTime / this.videoDuration) * 100;
    },
    seek(event) {
      const rect = event.currentTarget.getBoundingClientRect();
      const clickPosition = event.clientX - rect.left;
      const clickPercentage = Math.min(Math.max(clickPosition / rect.width, 0), 1);
      this.$refs.video.currentTime = this.videoDuration * clickPercentage;
      this.progressPercentage = clickPercentage * 100;
    },
    showPreview(marker) {
      this.hoveredMarker = marker;
    },
    hidePreview() {
      this.hoveredMarker = null;
    },
    startDragging(event) {
      this.isDragging = true;
      this.seek(event);
    },
    dragProgress(event) {
      if (this.isDragging) {
        this.seek(event);
      }
    },
    stopDragging() {
      this.isDragging = false;
    },
    stepFrame(direction, stepSize = 1) {
      const video = this.$refs.video;
      const frameTime = stepSize / 30;
      video.currentTime = Math.max(0, Math.min(this.videoDuration, video.currentTime + direction * frameTime));
      this.updateProgress();
    },
    handleKeyDown(event) {
      const stepSize = event.shiftKey ? 10 : 1;
      if (event.key === "ArrowLeft") {
        this.stepFrame(-1, stepSize);
      } else if (event.key === "ArrowRight") {
        this.stepFrame(1, stepSize);
      } else if (event.key === " ") {
        event.preventDefault();
        if (this.hoveredMarker) {
          this.markers = this.markers.filter((marker) => marker !== this.hoveredMarker);
          this.hoveredMarker = null;
        } else {
          this.captureFrame();
        }
      }
    },
    deleteMarker(marker) {
      this.markers = this.markers.filter((m) => m !== marker);
    },
    captureFrame() {
      const video = this.$refs.video;
      const canvas = document.createElement("canvas");
      canvas.width = video.videoWidth;
      canvas.height = video.videoHeight;
      const ctx = canvas.getContext("2d");
      ctx.drawImage(video, 0, 0, canvas.width, canvas.height);
      const thumbnail = canvas.toDataURL("image/png");
      this.addMarker(thumbnail);
      this.isCapturing = true;
      this.isFlashing = true;
      setTimeout(() => {
        this.isCapturing = false;
      }, 200);
      setTimeout(() => {
        this.isFlashing = false;
      }, 100);
    },
    addMarker(thumbnail) {
      const video = this.$refs.video;
      const time = video.currentTime;
      const markerExists = this.markers.some(marker => Math.abs(marker.time - time) < 1 / 30);
      if (!markerExists) {
        this.markers.push({ time, thumbnail });
      }
    },
  }
};
</script>
