<template>
  <div 
    :style="{ 
      width: layer.width + 'px',
      height: layer.height + 'px',
      position: 'relative',
      overflow: 'hidden',
      padding: computedRadius + 'px',
      borderRadius: '16px' // of via config
    }"
  >
    <!-- SVG Filter met dynamische kleur en radius -->
    <svg width="0" height="0">
      <defs>
        <filter :id="filterId">
          <feMorphology operator="dilate" :radius="computedRadius" in="SourceAlpha" result="thicken"/>
          <feFlood :flood-color="computedLineColor" />
          <feComposite in2="thicken" operator="in"/>
          <feMerge>
            <feMergeNode/>
            <feMergeNode in="SourceGraphic"/>
          </feMerge>
        </filter>
      </defs>
    </svg>

    <img
      :src="image"
      alt="Outlined PNG"
      :style="imageStyle"
    />
  </div>
</template>

<script>
export default {
  props: ['layer'],
  computed: {
    image() {
      return this.layer.config.image_outline_effect.image;
    },
    computedRadius() {
      // line_width direct als px:
      return Math.max(0, this.layer.config.image_outline_effect.line_width || 0);
    },
    computedLineColor() {
      return this.layer.config.image_outline_effect.line_color || '#ffffff';
    },
    filterId() {
      return `white-stroke-${this._uid}`;
    },
    imageStyle() {
      return {
        width: '100%',
        height: '100%',
        objectFit: 'contain',
        filter: `url(#${this.filterId})`
      };
    }
  }
};
</script>