var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"briefing_wizard__prompt_output",class:{
    'briefing_wizard__prompt_output--active': _vm.selected_version,
    'briefing_wizard__prompt_output--inactive': !_vm.selected_version && _vm.hasActiveIterationAbove
}},[_c('span',{staticClass:"profile_initial"},[_vm._v("A")]),_vm._v(" "),_c('div',{staticClass:"briefing_wizard__prompt"},[_c('div',{staticClass:"briefing_wizard__prompt_header"},[_c('span',{staticClass:"strong"},[_vm._v("Iteration "+_vm._s(_vm.iteration.uuid))]),_vm._v(" "),(_vm.created_at)?_c('span',{},[_vm._v(_vm._s(_vm.created_at))]):_vm._e()]),_vm._v(" "),(_vm.iteration.commands && _vm.iteration.commands.length)?_c('div',{staticClass:"command-pills"},_vm._l((_vm.iteration.commands),function(command){return _c('div',{key:command.mode + command.type,staticClass:"command-pill"},[_vm._v("\n                "+_vm._s(command.pretty_name)+"\n            ")])}),0):_vm._e(),_vm._v(" "),_c('div',{staticClass:"variations_grid",staticStyle:{"margin-top":"15px"}},[_c('transition',{attrs:{"name":"selected-version"}},[(_vm.selected_version)?_c('div',{staticClass:"variations_grid_item"},[_c('div',{staticClass:"selected_version_container"},[_c('div',{staticClass:"template_adopt adflow__briefing_small adflow__briefing_small--story selected",on:{"click":function($event){_vm.openStudio(_vm.getSelectedAd())}}},[(_vm.active_size && _vm.getSelectedAd() && _vm.getSelectedAd().preview_canvas_id)?_c('CanvasThumb',{ref:"canvas_thumb_main",attrs:{"canvas_id":_vm.getSelectedAd().preview_canvas_id,"brandkit":_vm.brandkit,"size":{ display_dimensions: [1080, 1920] }}}):_vm._e()],1),_vm._v(" "),_c('div',{staticClass:"selected_version_actions"},[_c('div',{staticClass:"briefing_wizard__prompt_actions"},[_c('div',{staticClass:"briefing_wizard__prompt_actions__row"},[_c('button',{staticClass:"briefing_wizard__prompt_button",class:{
                                        'briefing_wizard__prompt_button--active': _vm.selected_command && _vm.selected_command.type === 'assets'
                                    },on:{"click":function (e) { return _vm.set_command({
                                        type: 'assets',
                                        mode: 'shuffle',
                                        pretty_name: 'Shuffle assets',
                                        iteration_id: _vm.iteration.uuid
                                    }); }}},[_vm._v("Shuffle assets")]),_vm._v(" "),_c('Dropdown',{attrs:{"active":_vm.show_rephrase_dropdown,"root":true,"right":-75,"top":50,"on_toggle":function (state) { return _vm.show_rephrase_dropdown = state; }},scopedSlots:_vm._u([{key:"btn",fn:function(ref){
                                    var is_active = ref.is_active;
return [_c('button',{staticClass:"briefing_wizard__prompt_button",class:{
                                                'briefing_wizard__prompt_button--active': _vm.selected_command && _vm.selected_command.type === 'script'
                                            }},[_vm._v("Rephrase text\n                                                "),_c('i',{staticClass:"fa-regular fa-chevron-down"})])]}}],null,false,1381967416)},[_vm._v(" "),_c('div',{staticClass:"plan__select_plan__options",staticStyle:{"height":"150px","overflow-y":"scroll"}},_vm._l((_vm.rephrase_options),function(option,index){return _c('div',{key:("rephrase_opt_" + index),staticClass:"plan__select_plan__option",on:{"click":function (e) { return _vm.set_command({
                                                    type: 'script',
                                                    mode: option.mode,
                                                    pretty_name: option.name,
                                                    iteration_id: _vm.iteration.uuid
                                                }); }}},[_c('div',{staticClass:"plan__select_plan__option_group"},[_vm._v("\n                                                    "+_vm._s(option.name)+"\n                                                ")])])}),0)]),_vm._v(" "),_c('LanguagePicker',{attrs:{"right":-75,"top":50,"default_language":_vm.campaign_briefing.language,"active":_vm.selected_command && _vm.selected_command.type === 'language',"on_change":function (language) { return _vm.set_command({
                                            type: 'language',
                                            mode: language.value,
                                            pretty_name: 'translate to ' + language.label,
                                            iteration_id: _vm.iteration.uuid
                                        }); }}})],1)])])])]):_vm._e()]),_vm._v(" "),_c('div',{style:(("margin-top: " + (_vm.selected_version ? '-12px' : '0px') + ";"))},[(_vm.selected_version)?_c('h5',[_vm._v("Other Versions")]):_vm._e(),_vm._v(" "),_c('div',{staticClass:"variations_grid_others"},_vm._l((_vm.unselectedAds),function(ad){return _c('div',{key:("sug_it_" + (ad.id) + "_" + (ad.render_key)),staticClass:"variations_grid_item",on:{"click":function($event){return _vm.selectVersion(ad)}}},[_c('div',{staticClass:"template_adopt adflow__briefing_small adflow__briefing_small--story"},[(_vm.active_size && ad.preview_canvas_id)?_c('CanvasThumb',{attrs:{"canvas_id":ad.preview_canvas_id,"brandkit":_vm.brandkit,"size":{ display_dimensions: [1080, 1920] }}}):_vm._e()],1)])}),0)])],1)])])}
var staticRenderFns = []
export { render, staticRenderFns }