import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class TextRepeater extends Component {
  constructor({ opts } = { opts: {} }) {
    super()
    this.opts = opts
  }

  name() {
    return 'Text repeater'
  }

  labels() {
    return ['text']
  }

  icon() {
    return 'fa-brands fa-line'
  }

  configFields() {
    return {
      name: this.name(),
      options: [
        {
          type: 'textArea',
          name: 'Callout',
          hide_label: true,
          key: 'root.text',
        },
        {
          type: 'color',
          name: 'Text color',
          key: 'root.color',
        },
        {
          type: 'slider',
          name: 'Text line height',
          key: 'config.line_height',
          min: 0.1,
          max: 3,
          step: 0.01,
        },
      ],
    }
  }

  variants() {
    const baseConfig = this.baseConfig()

    return [
      {
        background: '#E1E3E5',
        layers: [{
          ...baseConfig,
          color: 'white',
          config: {
            ...baseConfig.config,
            text_repeater: {
              ...baseConfig.config.text_repeater,
            }
          },
        }]
      },
    ]
  }

  baseConfig() {
    return {
      layer_type: 'component',
      color: 'black',
      align: 'center',
      text: '50% OFF',
      width: 350,
      height: 350,
      ...this.opts,
      config: {
        v_align: 'center',
        line_height: 1,
        component: {
          type: 'text_repeater',
          name: 'Text repeater',
        },
        text_repeater: {
        }
      }
    }
  }

  config() {
    return {
      background: '#E1E3E5',
      layers: [
        Layer.factory(this.baseConfig())
      ]
    }
  }
}
