<template>
    <div :style="{ color: layer.color, width: layer.width + 'px', position: 'relative' }">
        <!-- SVG Achtergrond -->
        <svg 
            xmlns="http://www.w3.org/2000/svg" 
            :width="layer.width" 
            :height="layer.height"
            fill="none" 
            viewBox="0 0 54 54"
            style="
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
                z-index: -1;"
        >
            <path 
                :fill="layer.config.sticker_pricetag_circle.bg_color" 
                :fill-opacity="layer.config.sticker_pricetag_circle.show_bg ? 1 : 0"
                :stroke="layer.config.sticker_pricetag_circle.show_border ? layer.config.sticker_pricetag_circle.bg_border_color : 'none'" 
                :stroke-width="layer.config.sticker_pricetag_circle.border_width / 10"
                :d="shapePath" 
                :transform="shapeTransform"
            />
        </svg>

        <div
            :style="`
                display: flex;
                flex-direction: column;
                z-index: 0;
                gap: ${layer.config.sticker_pricetag_circle.gap}px;
            `"
        >
            <!-- Top text met line breaks -->
            <span 
                v-html="top_textWithBreaks"
                :style="{ 
                    color: layer.config.sticker_pricetag_circle.top_text_color, 
                    fontSize: layer.fontSize * 0.16 + 'px',
                    textDecoration: layer.config.sticker_pricetag_circle.line_through ? 'line-through' : 'none',
                    fontFamily: font,
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                }">
            </span>

            <div
                :style="`
                    display: flex;
                    flex-direction: ${layer.config.sticker_pricetag_circle.h_align};
                    align-items: ${layer.config.sticker_pricetag_circle.v_align};
                    justify-content: center;`"
            >   
                <!-- Hoofdtekst met line breaks -->
                <span
                    v-html="textWithBreaks"
                    :style="`
                        color: ${layer.color};
                        font-size: ${layer.fontSize * 0.55}px;
                        font-family: ${font};
                        white-space: normal;
                        word-wrap: break-word; 
                        overflow-wrap: break-word;`"
                ></span>

                <!-- Label zonder line breaks -->
                <span 
                    :style="{ 
                        color: layer.config.sticker_pricetag_circle.label_color, 
                        fontSize: layer.fontSize * 0.25 + 'px',
                        fontFamily: font,
                    }">
                    {{ label }}
                </span>
            </div>

            <!-- Bottom text met line breaks -->
            <span 
                v-html="bottom_textWithBreaks"
                :style="{ 
                    color: layer.config.sticker_pricetag_circle.bottom_text_color, 
                    fontSize: layer.fontSize * 0.16 + 'px',
                    fontFamily: font,
                    whiteSpace: 'normal',
                    wordWrap: 'break-word',
                    overflowWrap: 'break-word',
                }">
            </span>
        </div>
    </div>
</template>

<script>
import { highlight } from '../../../lib/parsers'
import FontMixin from '../mixins/font'
export default {
    props: ['layer', 'animation_uid'],
    mixins: [FontMixin],
    computed: {
        textWithBreaks() {
            if (!this.layer.text) return '';
            return this.layer.text.replace(/\n/g, '<br>');
        },
        top_textWithBreaks() {
            if (!this.layer.config.sticker_pricetag_circle.top_text) return '';
            return this.layer.config.sticker_pricetag_circle.top_text.replace(/\n/g, '<br>');
        },
        bottom_textWithBreaks() {
            if (!this.layer.config.sticker_pricetag_circle.bottom_text) return '';
            return this.layer.config.sticker_pricetag_circle.bottom_text.replace(/\n/g, '<br>');
        },
        component_name() {
            return this.layer.config.component.type
        },
        bgColor() {
            return this.layer.config[this.component_name].bg_color || 'white'
        },
        iconType() {
            return this.layer.config.sticker_pricetag_circle.icon_type || ''
        },
        label() {
            return this.layer.config.sticker_pricetag_circle.label
        },
        shapePath() {
            switch (this.layer.config.sticker_pricetag_circle.shape) {
                case 'circle':
                    return "M27,2 A25,25 0 1,1 26.9,2 Z";
                case 'wavy':
                    return "M29.4 54.8c-2.3 0-4.1-4.7-6.3-5.2-2.3-.5-5.9 2.8-7.9 1.7-2-1.1-1.4-6-3.1-7.5-1.7-1.5-6.5-.4-7.8-2.3-1.2-1.9 1.8-5.8 1.1-8-.6-2.1-5.3-3.8-5.3-6.1s4.7-4 5.3-6.1c.7-2.2-2.4-6.1-1.1-8 1.2-1.9 6.1-.8 7.8-2.3 1.7-1.5 1-6.4 3.1-7.5 2-1 5.7 2.3 7.9 1.7C25.3 4.7 27 0 29.3 0c2.3 0 4.1 4.7 6.3 5.2 2.3.5 5.9-2.8 7.9-1.7 2 1.1 1.4 6 3.1 7.5 1.7 1.5 6.5.4 7.8 2.3 1.2 1.9-1.8 5.8-1.1 8 .6 2.1 5.3 3.8 5.3 6.1s-4.7 4-5.3 6.1c-.7 2.2 2.4 6.1 1.2 8-1.2 1.9-6.1.8-7.8 2.3-1.7 1.5-1 6.4-3.1 7.5-2 1-5.7-2.3-7.9-1.7-2.3.5-4 5.2-6.3 5.2Z";
                case 'spikey':
                    return "M26.2 50.1c-1.2-1.9-3.7-2.5-5.6-1.3l-5.2 3.3-.3-6.2c-.1-2.3-2-4-4.3-3.9l-6.2.3 2.8-5.5c1-2 .3-4.5-1.7-5.6l-5.4-3 5.4-3c2-1.1 2.7-3.6 1.7-5.6L4.5 14l6.2.3c2.3.1 4.2-1.6 4.3-3.9l.3-6.2 5.3 3.3c1.9 1.2 4.4.6 5.6-1.4L29.4.8l3.2 5.3c1.2 1.9 3.7 2.5 5.6 1.3l5.2-3.3.3 6.2c.1 2.3 2 4 4.3 3.9l6.2-.3-2.8 5.5c-1 2-.3 4.5 1.7 5.6l5.4 3-5.4 3c-2 1.1-2.7 3.6-1.7 5.6l2.8 5.5-6.2-.3c-2.3-.1-4.2 1.6-4.3 3.9l-.3 6.2-5.3-3.2c-1.9-1.2-4.4-.6-5.6 1.4l-3.2 5.3-3.1-5.3Z";
                default:
                    return "";
            }
        },
        shapeTransform() {
            let scale, translateX, translateY;
            switch (this.layer.config.sticker_pricetag_circle.shape) {
                case 'circle':
                    scale = 1;
                    translateX = 27;
                    translateY = 27;
                    break;
                case 'wavy':
                    scale = 0.92;
                    translateX = 0;
                    translateY = 10;
                    break;
                case 'spikey':
                    scale = 0.9;
                    translateX = 10;
                    translateY = 14;
                    break;
                default:
                    scale = 1;
                    translateX = 27;
                    translateY = 27;
                    break;
            }
            return `translate(${translateX}, ${translateY}) scale(${scale}) translate(-${translateX}, -${translateY})`;
        }
    },
    methods: {
        parse(text) {
            return highlight.highlight(text,
                this.layer.config?.highlights?.background_color,
                this.layer.config?.highlights?.text_color)
        },
    }
}
</script>