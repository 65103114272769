<template>
  <div ref="container" :style="containerStyle">
    <!-- 
        Hier komt de tekst 50 keer onder elkaar.
        Met line-height regelen we de verticale spacing.
    -->
    <div ref="textRepeater" :style="textRepeaterStyle">
      <template v-for="i in 100">
        <span
          :key="'text-' + i"
          :style="computeStyle(i)"
        >
          {{ layer.text }}
        </span>
      </template>
    </div>
  </div>
</template>

<script>
import FontMixin from '../mixins/font'

export default {
  name: 'RepeaterText',        // of een andere componentnaam
  props: ['layer', 'animation_uid'],
  mixins: [FontMixin],

  data() {
    return {
      // Maximale fontgrootte voor de binaire zoekmethode
      maxInitialFontSize: 400
    }
  },

  computed: {
    // Stijlen voor de buitenste container
    containerStyle() {
      return {
        width: this.layer.width + 'px',
        height: this.layer.height + 'px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'flex-start', // start zodat hij bovenaan uitlijnt
        overflow: 'hidden'        // voorkomt eventueel horizontale scroll
      }
    },

    // Stijlen voor de tekstcontainer die de 50 herhalingen bevat
    textRepeaterStyle() {
      return {
        // We gebruiken flex voor het stapelen van regels
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        whiteSpace: 'nowrap'
        // Geen 'gap' meer: spacing doen we via line-height
      }
    },

    // Gewoon ter referentie, mocht je 'layer.text' niet gedefinieerd hebben
    text() {
      return this.layer.text || ''
    }
  },

  mounted() {
    // Wachten tot de fonts daadwerkelijk geladen zijn:
    document.fonts.ready.then(() => {
      this.$nextTick(() => {
        this.autoFitFontSize()
      })
    })
  },

  updated() {
    // Bij elke update: opnieuw schalen
    this.$nextTick(() => {
      this.autoFitFontSize()
    })
  },

  methods: {
    /**
     * Bepaalt de inline-styles per <span>.
     *  - Bij i === 1 => gevulde kleur + stroke
     *  - Bij i  > 1 => alleen stroke (transparante fill)
     */
    computeStyle(i) {
      const color = this.layer.color || '#000'
      const fontFamily = this.font

      // Stel de lineHeight in vanuit layer.config of neem 1.2 als fallback
      const lineHeight = this.layer.config.line_height || 1.2

      if (i === 1) {
        // Eerste regel: fill + stroke
        return `
          font-family: ${fontFamily};
          -webkit-text-fill-color: ${color};
          -webkit-text-stroke: 2px ${color};
          line-height: ${lineHeight};
        `
      } else {
        // Overige regels: alleen stroke (transparante fill)
        return `
          font-family: ${fontFamily};
          -webkit-text-fill-color: transparent;
          -webkit-text-stroke: 2px ${color};
          line-height: ${lineHeight};
        `
      }
    },

    /**
     * Past de totale fontgrootte van de <div ref="textRepeater"> aan
     * zodat de tekst (alle 50 regels) binnen de containerbreedte past.
     */
    autoFitFontSize() {
      const container = this.$refs.container
      const repeater = this.$refs.textRepeater

      if (!container || !repeater) return

      let min = 5
      let max = this.maxInitialFontSize
      let bestFit = min

      // We kunnen hier een vaste line-height instellen, maar
      // zetten we in computeStyle() per regel in dit voorbeeld.

      while (min <= max) {
        const mid = Math.floor((min + max) / 2)
        repeater.style.fontSize = mid + 'px'

        // Breedte van alle tekstregels samen
        const textWidth = repeater.scrollWidth
        const containerWidth = container.clientWidth

        if (textWidth <= containerWidth) {
          bestFit = mid
          min = mid + 1
        } else {
          max = mid - 1
        }
      }

      // Zet uiteindelijk de grootste passende fontgrootte
      repeater.style.fontSize = bestFit + 'px'
    }
  }
}
</script>
