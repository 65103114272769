export const STEPS = {
  PICK_FLOW: 0,
  UPLOAD: 1,
  REVIEW: 2,
  FROM_WEBSITE: 3,
  FROM_UGC_VIDEO: 4,
  SCENE_PICKER: 5,
}

export const ASSET_REQUIREMENTS = {
  [STEPS.FROM_UGC_VIDEO]: {
    min: 1,
    max: 1,
    allowed_types: ['video']
  },
  [STEPS.UPLOAD]: {
    min: 5,
    max: 12,
    allowed_types: ['image', 'video']
  },
  [STEPS.FROM_WEBSITE]: {
    min: 5,
    max: 12,
    allowed_types: ['image']
  }
}

export const SUPPORTED_LANGUAGES = [
  { value: 'dutch', label: 'Nederlands', flag: '🇳🇱' },
  { value: 'english', label: 'English', flag: '🇬🇧' },
  { value: 'german', label: 'Deutsch', flag: '🇩🇪' },
  { value: 'french', label: 'Français', flag: '🇫🇷' },
  { value: 'spanish', label: 'Español', flag: '🇪🇸' },
  { value: 'italian', label: 'Italiano', flag: '🇮🇹' },
  { value: 'portuguese', label: 'Português', flag: '🇵🇹' },
  { value: 'polish', label: 'Polski', flag: '🇵🇱' },
  { value: 'russian', label: 'Русский', flag: '🇷🇺' },
  { value: 'turkish', label: 'Türkçe', flag: '🇹🇷' },
  { value: 'greek', label: 'Ελληνικά', flag: '🇬🇷' },
  { value: 'swedish', label: 'Svenska', flag: '🇸🇪' },
  { value: 'danish', label: 'Dansk', flag: '🇩🇰' },
  { value: 'norwegian', label: 'Norsk', flag: '🇳🇴' },
  { value: 'finnish', label: 'Suomi', flag: '🇫🇮' },
  { value: 'czech', label: 'Čeština', flag: '🇨🇿' },
  { value: 'hungarian', label: 'Magyar', flag: '🇭🇺' },
  { value: 'romanian', label: 'Română', flag: '🇷🇴' },
  { value: 'bulgarian', label: 'Български', flag: '🇧🇬' },
  { value: 'croatian', label: 'Hrvatski', flag: '🇭🇷' },
  { value: 'ukrainian', label: 'Українська', flag: '🇺🇦' }
]

export const FUNNEL_STAGES = [
  {
    value: 'TopOfFunnel',
    label: 'Top of Funnel (TOFU)',
    description: "People who don't know you or even realize they have a problem yet."
  },
  {
    value: 'MiddleOfFunnel',
    label: 'Middle of Funnel (MOFU)',
    description: 'People who know they have a problem and are actively looking for solutions.'
  },
  {
    value: 'BottomOfFunnel',
    label: 'Bottom of Funnel (BOFU)',
    description: 'People who are ready to take action and need a reason to choose you.'
  }
]
