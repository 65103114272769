import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class StickerPricetagBasic extends Component {
  constructor({ opts } = { opts: {} }) {
    super()
    this.opts = opts
  }

  name() {
    return 'Price tag basic'
  }

  labels() {
    return ['sticker']
  }

  icon() {
    return 'fa-brands fa-line'
  }

  configFields() {
    return {
      name: this.name(),
      options: [
        // Rij 1: top text
        [
          {
            type: 'textArea',
            name: 'Top text',
            key: 'top_text',
          },
          {
            type: 'toggle',
            name: 'Strike',
            key: 'line_through',
          },
          {
            type: 'color',
            name: 'Color',
            key: 'top_text_color',
          },
        ],

        // Rij 2: main text
        [
          {
            type: 'textArea',
            name: 'Text',
            hide_label: false,
            key: 'root.text',
          },
          {
            type: 'color',
            name: 'Color',
            key: 'root.color',
          },
        ],

        // Rij 3: achtergrondkleuren
        [
          {
            type: 'color',
            name: 'Top BG color',
            key: 'top_bg_color',
          },
          {
            type: 'color',
            name: 'Main BG color',
            key: 'main_bg_color',
          },
        ],

        // Nieuw: Knoppen voor flex direction

        {
          type: 'buttonGroup',
          name: 'Direction',
          key: 'direction',
          options: [
            {
              type: 'icon',
              name: 'Row',
              icon: 'fa-regular fa-arrow-right', // Een horizontale lijn om 'row' te symboliseren
              value: 'row',
            },
            {
              type: 'icon',
              name: 'Column',
              icon: 'fa-regular fa-arrow-down', // Een verticale lijn om 'column' te symboliseren
              value: 'column',
            },
          ],
          default: 'column'
        },

        {
          type: 'buttonGroup',
          name: 'Align',
          key: 'h_align',
          options: [
            {
              type: 'icon',
              name: 'Left',
              icon: 'fa-regular fa-left',
              value: 'flex-start',
            },
            {
              type: 'icon',
              name: 'Center',
              icon: 'fa-regular fa-left-right',
              value: 'center',
            },
            {
              type: 'icon',
              name: 'Right',
              icon: 'fa-regular fa-right',
              value: 'flex-end',
            },
          ]
        },

        // Nieuw: Slider voor top-tekst-ratio
        {
          type: 'slider',
          name: 'Top text ratio (%)',
          key: 'top_text_ratio',
          min: 10,
          max: 100,
          step: 1,
        },

        // Slider voor tekstgrootte
        {
          type: 'slider',
          name: 'Text size',
          key: 'root.fontSize',
          min: 0,
          max: 300,
          step: 1,
        },

        // Nieuw: Slider voor border radius
        {
          type: 'slider',
          name: 'Corner radius',
          key: 'bg_radius',
          min: 0,
          max: 50,
          step: 1,
        },

        // Nieuw: Slider voor padding
        {
          type: 'slider',
          name: 'Padding (px)',
          key: 'bg_padding',
          min: 0,
          max: 50,
          step: 1,
        },

        // Overige opties
        {
          type: 'slider',
          name: 'Distance',
          key: 'gap',
          min: 0,
          max: 100,
          step: 1,
        },
      ],
    }
  }

  variants() {
    const baseConfig = this.baseConfig()

    return [
      {
        background: '#E1E3E5',
        layers: [{
          ...baseConfig,
          config: {
            ...baseConfig.config,
            sticker_pricetag_basic: {
              ...baseConfig.config.sticker_pricetag_basic,
              direction: 'row',
              top_bg_color: 'red',
              top_text_ratio: 46,
              h_align: 'flex-start',
              top_text: 'SALE',
              top_text_color: 'white',
            }
          },
        }]
      },
      {
        background: '#E1E3E5',
        layers: [{
          ...baseConfig,
          fontSize: 135,
          text: '€19,95',
          config: {
            ...baseConfig.config,
            sticker_pricetag_basic: {
              ...baseConfig.config.sticker_pricetag_basic,
              line_through: true,
              direction: 'row',
              bg_radius: 18,
              top_bg_color: '#FFFF00',
              top_text_ratio: 42,
              h_align: 'center',
              gap: 6,
              top_text: '€29,95',
              top_text_color: 'black',
            }
          },
        }]
      },
      {
        background: '#E1E3E5',
        layers: [{
          ...baseConfig,
          fontSize: 135,
          text: '€19,95',
          config: {
            ...baseConfig.config,
            sticker_pricetag_basic: {
              ...baseConfig.config.sticker_pricetag_basic,
              line_through: true,
              bg_radius: 18,
              top_bg_color: 'red',
              top_text_ratio: 42,
              gap: 6,
              top_text: '€29,95',
              top_text_color: 'white',
            }
          },
        }]
      },
    ]
  }

  baseConfig() {
    return {
      layer_type: 'component',
      color: 'black',
      align: 'center',
      text: '-50%',
      width: 500,
      height: 200,
      fontSize: 110,
      background: '#FF4B4B',
      ...this.opts,
      config: {
        line_height: 1,
        v_align: 'center',
        component: {
          type: 'sticker_pricetag_basic',
          name: 'Sticker pricetag basic',
        },
        sticker_pricetag_basic: {
          // Bestaande keys...
          top_text: 'NOW',
          top_text_color: 'white',
          line_through: false,
          main_bg_color: '#FFFFFF',
          top_bg_color: 'black',
          border_width: 10,
          h_align: 'flex-start',
          shape: 'circle',
          gap: 0,
          direction: 'column',
          top_text_ratio: 50,
          bg_radius: 0,
          bg_padding: 15,
        }
      }
    }
  }

  config() {
    return {
      background: '#E1E3E5',
      layers: [
        Layer.factory(this.baseConfig())
      ]
    }
  }
}
