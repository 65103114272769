<template>
    <div class="briefing_wizard__prompt_output" :class="{
        'briefing_wizard__prompt_output--active': selected_version,
        'briefing_wizard__prompt_output--inactive': !selected_version && hasActiveIterationAbove
    }">
        <span class="profile_initial">A</span>
        <div class="briefing_wizard__prompt">
            <div class="briefing_wizard__prompt_header">
                <span class="strong">Iteration {{ iteration.uuid }}</span>
                <span class="" v-if="created_at">{{ created_at }}</span>
            </div>

            <!-- Add command pills here -->
            <div v-if="iteration.commands && iteration.commands.length" class="command-pills">
                <div v-for="command in iteration.commands" :key="command.mode + command.type" class="command-pill">
                    {{ command.pretty_name }}
                </div>
            </div>

            <div class="variations_grid" style="margin-top: 15px;">
                <!-- Selected Version -->
                <transition name="selected-version">
                    <div v-if="selected_version" class="variations_grid_item">
                        <div class="selected_version_container">
                            <!-- Selected Ad -->
                            <div class="template_adopt adflow__briefing_small adflow__briefing_small--story selected"
                                @click="openStudio(getSelectedAd())">
                                <CanvasThumb v-if="active_size && getSelectedAd() && getSelectedAd().preview_canvas_id"
                                    :canvas_id="getSelectedAd().preview_canvas_id" :brandkit="brandkit"
                                    :size="{ display_dimensions: [1080, 1920] }" ref="canvas_thumb_main" />
                            </div>

                            <!-- Action Buttons -->
                            <div class="selected_version_actions">
                                <div class="briefing_wizard__prompt_actions">
                                    <div class="briefing_wizard__prompt_actions__row">
                                        <button class="briefing_wizard__prompt_button" :class="{
                                            'briefing_wizard__prompt_button--active': selected_command && selected_command.type === 'assets'
                                        }" @click="e => set_command({
                                            type: 'assets',
                                            mode: 'shuffle',
                                            pretty_name: 'Shuffle assets',
                                            iteration_id: iteration.uuid
                                        })">Shuffle assets</button>

                                        <Dropdown :active="show_rephrase_dropdown" :root="true" :right="-75" :top="50"
                                            :on_toggle="state => show_rephrase_dropdown = state">
                                            <template slot="btn" slot-scope="{is_active}">
                                                <button class="briefing_wizard__prompt_button" :class="{
                                                    'briefing_wizard__prompt_button--active': selected_command && selected_command.type === 'script'
                                                }">Rephrase text
                                                    <i class="fa-regular fa-chevron-down"></i>
                                                </button>
                                            </template>
                                            <div class="plan__select_plan__options"
                                                style="height: 150px; overflow-y: scroll;">
                                                <div class="plan__select_plan__option" :key="`rephrase_opt_${index}`"
                                                    v-for="(option, index) in rephrase_options" v-on:click="e => set_command({
                                                        type: 'script',
                                                        mode: option.mode,
                                                        pretty_name: option.name,
                                                        iteration_id: iteration.uuid
                                                    })">
                                                    <div class="plan__select_plan__option_group">
                                                        {{ option.name }}
                                                    </div>
                                                </div>
                                            </div>
                                        </Dropdown>

                                        <LanguagePicker :right="-75" :top="50"
                                            :default_language="campaign_briefing.language"
                                            :active="selected_command && selected_command.type === 'language'"
                                            :on_change="language => set_command({
                                                type: 'language',
                                                mode: language.value,
                                                pretty_name: 'translate to ' + language.label,
                                                iteration_id: iteration.uuid
                                            })" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </transition>

                <div :style="`margin-top: ${selected_version ? '-12px' : '0px'};`">
                    <!-- Other Versions -->
                    <h5 v-if="selected_version">Other Versions</h5>
                    <div class="variations_grid_others">
                        <div v-for="ad in unselectedAds" :key="`sug_it_${ad.id}_${ad.render_key}`"
                            class="variations_grid_item" @click="selectVersion(ad)">
                            <div class="template_adopt adflow__briefing_small adflow__briefing_small--story">
                                <CanvasThumb v-if="active_size && ad.preview_canvas_id"
                                    :canvas_id="ad.preview_canvas_id" :brandkit="brandkit"
                                    :size="{ display_dimensions: [1080, 1920] }" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import CompactPlayer from '../studio/components/compact_player.vue'
import Dropdown from '../components/dropdown.vue'
import LanguagePicker from './language_picker.vue'
import CanvasThumb from '../image-editor/canvas_thumb.vue'
import studioApi from '../studio/store/api'

export default {
    components: {
        CompactPlayer,
        Dropdown,
        LanguagePicker,
        CanvasThumb
    },
    props: {
        show_previews: {
            type: Boolean,
            required: true
        },
        active_size: {
            type: Object,
            required: true
        },
        active_ad: {
            type: Object,
            required: false
        },
        brandkit: {
            type: Object,
            required: true
        },
        iteration: {
            type: Object,
            required: true
        },
        on_commands_change: {
            type: Function,
            required: true
        },
        on_active_ad_change: {
            type: Function,
            required: true
        },
        campaign_briefing: {
            type: Object,
            required: true
        },
        last: {
            type: Boolean,
            required: false,
            default: false
        },
        hasActiveIterationAbove: {
            type: Boolean,
            required: false,
            default: false
        }
    },
    data() {
        return {
            l_ads: [this.iteration.ads],
            show_rephrase_dropdown: false,
            show_language_dropdown: false,
            rephrase_options: [
                { name: "Rephrase", mode: "rephrase" },
                { name: "Highlight keywords", mode: "highlight_keywords" },
                { name: "Simplify the message", mode: "simplify" },
                { name: "Add more emoji's 😎", mode: "add_emojis" },
                { name: "Add more call to actions", mode: "add_cta" },
                { name: "Add more urgency", mode: "add_urgency" },
                { name: "Add more humor", mode: "add_humor" },
                { name: "Add more benefits", mode: "add_benefits" },
                { name: "Add more social proof", mode: "add_social_proof" },
                { name: "Add more scarcity", mode: "add_scarcity" },
                { name: "Add more storytelling", mode: "add_storytelling" },
                { name: "Add more authority", mode: "add_authority" },
                { name: "Add more curiosity", mode: "add_curiosity" },
                { name: "Add more FOMO", mode: "add_fomo" },
            ],
            selected_version: null,
            selected_command: null,
            command_composition: {
                'version': null,
                'assets': null,
                'script': null,
                'language': null
            },
            active_briefing_loading: false
        }
    },
    watch: {
        iteration: {
            deep: true,
            immediate: true,
            handler(newval, oldval) {
                this.l_ads = newval.ads
            }
        }
    },
    computed: {
        created_at() {
            try {
                const date = new Date(this.iteration.ads[0].created_at * 1000)
                let str = date.toLocaleString()
                if (str === 'Invalid Date') {
                    return 'Now'
                } else {
                    return str
                }
            } catch (e) {
                return 'Now'
            }
        },
        unselectedAds() {
            if (!this.selected_version) return this.l_ads;
            return this.l_ads.filter(ad => ad.id !== this.selected_version.id);
        }
    },
    methods: {
        set_active_ad(ad) {
            this.on_active_ad_change(ad)
            this.set_version({
                type: 'version',
                pretty_name: `V${this.iteration.ads.indexOf(ad) + 1}`,
                id: ad.id,
                mode: null,
                iteration_id: this.iteration.uuid
            })
        },
        force_update(briefing) {
            this.l_ads = this.l_ads.map(ad => {
                if (ad.id === briefing.id) {
                    return { ...ad, render_key: Math.random() }
                }
                return ad
            })
            this.$refs.canvas_thumb_main.reload()
        },
        clear_selection() {
            this.selected_version = null
            this.selected_command = null
            this.command_composition = {
                'version': null,
                'assets': null,
                'script': null,
                'language': null
            }
        },
        find_version(command) {
            return this.selected_version?.id == command.id
        },
        find_command(command) {
            return this.command_composition[command.type]?.id == command?.id
        },
        set_command(command) {
            this.show_rephrase_dropdown = false
            this.show_language_dropdown = false
            this.selected_command = command
            this.on_commands_change({
                iteration_id: this.iteration.uuid,
                commands: [
                    this.selected_version,
                    this.selected_command
                ].filter(Boolean)
            })
        },
        set_version(command) {
            this.selected_version = command
            this.on_commands_change({
                iteration_id: this.iteration.uuid,
                commands: [
                    this.selected_version,
                    this.selected_command
                ].filter(Boolean)
            })
        },
        toggle_command(command) {
            this.show_rephrase_dropdown = false
            let command_type_isset = !!this.command_composition[command.type]
            let command_mode_is_eql = this.command_composition[command.type]?.mode == command.mode
            let command_target_is_eql = this.command_composition[command.type]?.id == command.id
            if (command.type !== 'version')
                command_target_is_eql = false

            if (command_type_isset && (command_mode_is_eql || command_target_is_eql)) {
                this.command_composition[command.type] = null
            } else {
                this.command_composition[command.type] = command
            }

            this.on_commands_change({
                iteration_id: this.iteration.uuid,
                commands: Object.keys(this.command_composition).map(key => this.command_composition[key]).filter(Boolean)
            })
        },
        async openStudio(ad) {
            if (ad) {
                try {
                    // Show loading state if needed
                    this.active_briefing_loading = true;

                    // Fetch complete briefing data from server
                    const result = await studioApi.get_briefing(ad);

                    // Pass the complete briefing data to parent
                    this.on_active_ad_change(result);
                } catch (error) {
                    console.error('Error fetching briefing data:', error);
                    // Handle error - maybe show a notification to user
                } finally {
                    this.active_briefing_loading = false;
                }
            }
        },
        selectVersion(ad) {
            // Force a reset of selected_version first
            this.selected_version = null;

            // Wait for the DOM to update after clearing
            this.$nextTick(() => {
                // Then set the new version
                this.selected_version = {
                    type: 'version',
                    pretty_name: `V${this.l_ads.indexOf(ad) + 1}`,
                    id: ad.id,
                    mode: null,
                    iteration_id: this.iteration.uuid
                };

                // Notify parent of the change
                this.set_version(this.selected_version);

                // Scroll to position
                this.$nextTick(() => {
                    const element = this.$el;
                    if (element) {
                        const container = document.querySelector('.briefing_wizard__output');
                        if (container) {
                            const elementRect = element.getBoundingClientRect();
                            const containerRect = container.getBoundingClientRect();
                            const relativeTop = elementRect.top - containerRect.top;

                            container.scrollTo({
                                top: container.scrollTop + relativeTop - 20,
                                behavior: 'smooth'
                            });
                        }
                    }
                });
            });
        },
        getAdById(id) {
            return this.l_ads.find(ad => ad.id === id)
        },
        getSelectedAd() {
            if (!this.selected_version || !this.selected_version.id) return null;
            return this.getAdById(this.selected_version.id);
        }
    }
}
</script>