<template>
    <span>
        <modal name="asset_bucket" :focusTrap="true" :clickToClose="false" :classes="['modal__flex']" :width="'80%'"
            :height="'85%'">


            <div class="modal__header modal__header--underlined">
                <div class="modal__header_container">
                    <h3>Create Ads</h3>
                    <p>Add your images and videos and turn them into captivating ads that highlight your products value
                        and drive sales.</p>
                </div>


                <a class="button button--light" v-if="!(fromExistingBriefing && step === STEPS.UPLOAD)"
                    @click="fromExistingBriefing ? step = STEPS.UPLOAD : step = STEPS.PICK_FLOW">
                    <i class="fa-solid fa-chevron-left"></i>
                </a>
            </div>

            <div class="modal__flex_body" style="flex-direction: column; gap: 24px;">

                <FlowSelector v-if="step === STEPS.PICK_FLOW" @flow-selected="handleFlowSelection" />

                <CampaignForm v-if="step === STEPS.REVIEW" :initial-data="campaign_briefing"
                    @update:form="updateCampaignData" @validation="handleFormValidation" />


                <WebsiteFeed v-if="step === STEPS.FROM_WEBSITE" :campaignBriefingId="campaign_briefing.id"
                    @state-change="state => processWebsiteFeedState(state)" :brand_homepage="brand_homepage" />

                <div v-if="step === STEPS.UPLOAD || step === STEPS.REVIEW || step === STEPS.FROM_UGC_VIDEO"
                    class="brieding_wizard__upload">

                    <Medialib ref="medialib" :show="true" :fixed="false"
                        :only_types="currentAssetRequirements.allowed_types"
                        :feed_id="feed ? feed.complex_identifier : null" :select_on_upload="true"
                        :on_asset="select_asset" :default_tab="default_tab" :show_filters="false">

                        <template v-slot:virgin>
                            <div class="medialibrary__virgin_container">
                                <div class="medialibrary__virgin_container_holder">
                                    <i class="fa-regular fa-cloud-arrow-up"></i>
                                    <p class="strong">Drag & Drop your files (jpg, png, mp4)</p>
                                </div>
                            </div>
                        </template>

                        <template v-slot:footer>
                            <SelectedAssets :assets="selected_assets" @remove="delete_feed_row" />
                        </template>
                    </Medialib>
                </div>

                <div v-else-if="step === STEPS.SCENE_PICKER">
                    <VideoScenePicker :onMarkersChange="updateMarkers" :videoSrc="selected_assets[0].asset_url"
                        ref="videoScenePicker" />
                    <SelectedAssets :assets="markers" @remove="removeMarker" />
                </div>

                <transition name="upload-instructions" mode="out-in">
                    <UploadInstructions v-if="step === STEPS.UPLOAD && selected_assets.length < 1"
                        :key="'upload-instructions'" />
                </transition>

            </div>
            <div class="modal__footer tweener" v-if="picked_flow">
                <Transition name="fade">
                    <span v-if="assets.length < currentAssetRequirements.min">
                        Select <span class="strong">{{ currentAssetRequirements.min - assets.length }}</span>
                        more asset{{ (currentAssetRequirements.min - assets.length) > 1 ? 's' : '' }} for the best
                        result
                    </span>
                    <span v-else-if="picked_flow === STEPS.FROM_UGC_VIDEO && !extracting">
                        Great! You have selected <span class="strong">{{ assets.length }}</span> asset{{
                            assets.length > 1 ? 's' : '' }}. Now select the scenes you want to use.
                    </span>
                    <span v-else-if="step === STEPS.REVIEW && !isFormValid">
                        Please fill in all required fields in the form above
                    </span>
                    <span v-else>
                        Great! You have selected <span class="strong">{{ assets.length }}</span> assets
                    </span>
                </Transition>

                <span v-if="picked_flow === STEPS.FROM_UGC_VIDEO">
                    <button v-if="step === STEPS.SCENE_PICKER" @click="upload_and_next" :disabled="extracting"
                        class="button button--big button--gradient">
                        <span v-if="extracting">
                            <i class="fa-solid fa-spinner-third fa-spin"></i>
                            <span>Extracting...</span>
                        </span>
                        <span v-else>Next</span>
                    </button>

                    <button v-else @click="next" :disabled="false" class="button button--big button--gradient">{{
                        step
                            == STEPS.REVIEW ?
                            "Generate" : "Next" }}</button>
                </span>

                <button v-else @click="next"
                    :disabled="assets.length < currentAssetRequirements.min || (step === STEPS.REVIEW && !isFormValid)"
                    :class="[
                        'button button--big button--gradient',
                        { 'button--disabled': step === STEPS.REVIEW && !isFormValid }
                    ]">
                    {{ step == STEPS.REVIEW ? "Generate" : "Next" }}
                </button>

                <button class="button button--light" @click="e => on_finish(null, false)" v-if="fromExistingBriefing">
                    close
                </button>

            </div>
        </modal>
    </span>
</template>

<script>
import Medialib from '../studio/components/medialib.vue'
import StudioApi from '../studio/store/api.js'
import Video from '../studio/components/video.vue'
import VideoScenePicker from './flows/steps/video_extractor.vue'
import FlowSelector from './components/FlowSelector.vue'
import CampaignForm from './components/CampaignForm.vue'
import SelectedAssets from './components/SelectedAssets.vue'
import UploadInstructions from './components/UploadInstructions.vue'
import { STEPS, ASSET_REQUIREMENTS } from './constants'
import WebsiteFeed from './components/WebsiteFeed.vue'

export default {
    name: 'BriefingForm',
    components: {
        Medialib,
        Video,
        VideoScenePicker,
        FlowSelector,
        CampaignForm,
        SelectedAssets,
        UploadInstructions,
        WebsiteFeed
    },
    props: {
        show: {
            type: Boolean,
            required: true,
            default: false
        },

        campaign_briefing: {
            type: Object,
            required: true
        },
        on_finish: {
            type: Function,
            required: true,
            default: () => { }
        },
        brand_homepage: {
            type: String,
            required: false,
            default: null
        }
    },
    data() {
        return {
            STEPS,
            ASSET_REQUIREMENTS,
            step: STEPS.PICK_FLOW,
            picked_flow: null,
            markers: [],
            feed: this.campaign_briefing.feed,
            selected_assets: [],
            isFormValid: false,
            fromExistingBriefing: false,
            extracting: false
        }
    },
    computed: {
        assets() {
            return this.selected_assets
        },
        default_tab() {
            if (this.step === STEPS.REVIEW) return null
            return 'assets'
        },
        currentAssetRequirements() {
            return this.picked_flow ? ASSET_REQUIREMENTS[this.picked_flow] : {
                min: 1,
                max: 12,
                allowed_types: ['image', 'video']
            }
        }
    },
    mounted() {
        setTimeout(() => {
            if (this.show) this.$modal.show('asset_bucket')
        }, 1)
        this.selected_assets = this.campaign_briefing.feed.data.map(r => r.data)
    },
    watch: {
        show: {
            immediate: true,
            handler(newVal) {
                if (newVal) {
                    this.resetForm()
                    this.$modal.show('asset_bucket')
                    this.selected_assets = this.campaign_briefing.feed.data.map(r => r.data)
                    this.initializeExistingBriefing()
                } else {
                    this.$modal.hide('asset_bucket')
                }
            }
        },
    },
    methods: {
        processWebsiteFeedState(state) {
            if (state.status === 'asset') {
                this.select_asset(state.object.feed_element)
            }

            if (state.status === 'finished') {
                this.step = STEPS.UPLOAD
            }
        },
        resetForm() {
            this.step = STEPS.PICK_FLOW
            this.picked_flow = null
            this.markers = []
            this.extracting = false
            this.isFormValid = false
        },
        initializeExistingBriefing() {
            if (this.selected_assets.length > 0) {
                this.step = STEPS.REVIEW
                const hasVideos = this.selected_assets.some(asset =>
                    asset.content_type === 'video' ||
                    asset.asset_url?.toLowerCase().includes('mp4')
                )
                this.isFormValid = true
                this.fromExistingBriefing = true
                this.picked_flow = hasVideos ? STEPS.FROM_UGC_VIDEO : STEPS.UPLOAD
            }
        },
        validate() {
            return this.isFormValid && this.selected_assets.length >= this.currentAssetRequirements.min
        },
        handleFlowSelection(flow) {
            this.step = STEPS[flow]
            this.picked_flow = STEPS[flow]
            if (this.selected_assets.length > 0) {
                this.step = STEPS.REVIEW
            }
        },
        updateCampaignData(data) {
            Object.assign(this.campaign_briefing, data)
        },
        updateMarkers(marks) {
            this.markers = marks
        },
        removeMarker(marker, index) {
            this.$refs.videoScenePicker.removeMarker(marker, index)
        },
        async next() {
            if (this.step === STEPS.PICK_FLOW) {
                this.step = STEPS.UPLOAD
            } else if (this.step === STEPS.UPLOAD) {
                this.step = STEPS.REVIEW
            } else if (this.step === STEPS.FROM_UGC_VIDEO) {
                this.step = STEPS.SCENE_PICKER
            } else if (this.step === STEPS.FROM_WEBSITE) {
                this.step = STEPS.REVIEW
            } else {
                await this.save_and_finish()
            }
        },
        async upload_and_next() {
            this.extracting = true
            const assets = await this.create_assets(this.markers.map(m => m.thumbnail), { external: true })
            for (let asset of this.selected_assets) {
                await this.delete_feed_row({ data: asset })
            }
            for (let asset of assets) {
                await this.select_asset(asset.feed_element, { ignore_max: true })
            }
            this.step = STEPS.REVIEW
            this.extracting = false
        },
        async create_assets(files, opts = { external: false }) {
            const fileObjects = files.map(file => {
                if (typeof file === 'string' && file.startsWith('data:image')) {
                    const byteString = atob(file.split(',')[1])
                    const mimeString = file.split(',')[0].split(':')[1].split(';')[0]
                    const ab = new ArrayBuffer(byteString.length)
                    const ia = new Uint8Array(ab)
                    for (let i = 0; i < byteString.length; i++) {
                        ia[i] = byteString.charCodeAt(i)
                    }
                    const blob = new Blob([ab], { type: mimeString })
                    return new File([blob], 'image.png', { type: mimeString })
                }
                return file
            })

            const assets = await Promise.all(fileObjects.map(async file => {
                return await StudioApi.upload_file(file, {
                    async: false,
                    onUploadProgress: progressEvent => {
                        const { loaded, total } = progressEvent
                        const percentage = Math.floor((loaded * 100) / total)
                    }
                })
            }))

            return assets
        },
        async save_and_finish() {
            if (!this.validate()) {
                return false
            }
            const result = await StudioApi.update_campaign_briefing({
                goal: this.campaign_briefing.goal,
                language: this.campaign_briefing.language,
                name: this.campaign_briefing.name,
                id: this.campaign_briefing.id,
                funnel_stage: this.campaign_briefing.funnel_stage,
                audience: this.campaign_briefing.audience,
                benefit: this.campaign_briefing.benefit,
                context: this.campaign_briefing.context
            })
            this.on_finish(result)
            return true
        },
        async select_asset(asset, opts = { ignore_max: false }) {
            if (this.selected_assets.length >= this.currentAssetRequirements.max && !opts.ignore_max) {
                return
            }
            const result = await this.add_asset(asset)
            this.selected_assets = [...this.selected_assets, result.data]
        },
        async add_asset(row) {
            return await StudioApi.create_feed_row(this.feed.complex_identifier, { data: row })
        },
        async delete_feed_row(row) {
            try {
                // Delete from backend
                await StudioApi.delete_feed_row(this.feed.complex_identifier, row['adflow_row_id'])

                // Update local state
                this.selected_assets = this.selected_assets.filter(asset =>
                    asset['adflow_row_id'] !== row['adflow_row_id']
                )

                // Reset to PICK_FLOW if no assets left (except for UGC video flow)
                if (this.selected_assets.length === 0 && this.picked_flow !== STEPS.FROM_UGC_VIDEO) {
                    this.step = STEPS.PICK_FLOW
                    this.picked_flow = null
                }

                // Update feed data in campaign briefing
                this.campaign_briefing.feed.data = this.campaign_briefing.feed.data.filter(feedRow =>
                    feedRow.data['adflow_row_id'] !== row['adflow_row_id']
                )
            } catch (error) {
                console.error('Error deleting asset:', error)
                // Optionally add error handling/user notification here
            }
        },
        handleFormValidation(isValid) {
            this.isFormValid = isValid
            this.$emit('validation', this.isFormValid)
        }
    }
}
</script>