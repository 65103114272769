import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class CalloutToolTip extends Component {
  constructor({ opts } = { opts: {} }) {
    super()
    this.opts = opts
  }

  name() {
    return 'Callout tooltip'
  }

  labels() {
    return ['callout']
  }

  icon() {
    return 'fa-brands fa-line'
  }

  configFields() {
    return {
      name: this.name(),
      options: [
        {
          type: 'textArea',
          name: 'Callout',
          hide_label: true,
          key: 'root.text',
        },
        [
          {
            type: 'color',
            name: 'Text',
            key: 'root.color',
          },
          {
            type: 'color',
            name: 'Bg',
            key: 'bg_color',
          },
        ],
        {
          type: 'buttonGroup',
          name: 'Alignment',
          hide_label: false,
          key: 'align',
          options: [
            {
              type: 'icon',
              name: 'Left',
              icon: 'fa-regular fa-arrow-left',
              value: 'left',
            },
            {
              type: 'icon',
              name: 'Right',
              icon: 'fa-regular fa-arrow-right',
              value: 'right',
            },
            {
              type: 'icon',
              name: 'Left',
              icon: 'fa-regular fa-arrow-up',
              value: 'top',
            },
            {
              type: 'icon',
              name: 'Right',
              icon: 'fa-regular fa-arrow-down',
              value: 'bottom',
            }
          ]
        },
      ],
    }
  }

  variants() {
    const baseConfig = this.baseConfig()

    return [
      {
        background: '#E1E3E5',
        layers: [{
          ...baseConfig,
          color: 'black',
          config: {
            ...baseConfig.config,
            callout_tooltip: {
              ...baseConfig.config.callout_tooltip,
              align: 'left',
              bg_color: 'white',
              text_color: 'black',
            }
          },
        }]
      },
    ]
  }

  baseConfig() {
    return {
      layer_type: 'component',
      color: 'white',
      align: 'center',
      text: 'Sample callout',
      width: 300,
      height: 200,
      fontSize: 69,
      ...this.opts,
      config: {
        v_align: 'center',
        show_background: false,
        component: {
          type: 'callout_tooltip',
          name: 'Callout tooltip',
        },
        callout_tooltip: {
          align: 'left',
          text_color: 'white',
          bg_color: '#313131',
        }
      }
    }
  }

  config() {
    return {
      background: '#E1E3E5',
      layers: [
        Layer.factory(this.baseConfig())
      ]
    }
  }
}
