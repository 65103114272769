<template>
  <div ref="container" :style="containerStyle">
    <div ref="textElement" :style="textElementStyle">
      <div v-for="(line, index) in lines" :key="index" class="line-container" ref="lineContainers" :data-index="index"
        :style="{ fontFamily: font, lineHeight: layer.config.line_height }">
        {{ line }}
      </div>
    </div>
  </div>
</template>

<script>
import FontMixin from '../mixins/font'
export default {
  props: ['layer', 'animation_uid'],
  mixins: [FontMixin],
  data() {
    return {
      maxInitialFontSize: 400
    }
  },
  computed: {
    component_name() {
      return this.layer.config.component.type
    },
    bgColor() {
      return this.layer.config[this.component_name].bg_color || 'white'
    },
    lines() {
      const text = this.layer.text || ''
      return text.split('\n')
    },
    containerStyle() {
      return {
        color: this.layer.color,
        width: this.layer.width + 'px',
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
      }
    },
    textElementStyle() {
      return {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        whiteSpace: 'nowrap'
      }
    },
  },
  mounted() {
    document.fonts.ready.then(() => {
      this.$nextTick(() => {
        this.adjustAllLinesFontSize()
      })
    })
  },
  updated() {
    this.$nextTick(() => {
      this.adjustAllLinesFontSize()
    })
  },
  methods: {
    adjustAllLinesFontSize() {
      const container = this.$refs.container
      const lineContainers = this.$refs.lineContainers
      if (!container || !lineContainers) return

      const containerWidth = container.clientWidth
      lineContainers.forEach(lineEl => {
        this.autoFitFontSize(lineEl, containerWidth)
      })
    },
    autoFitFontSize(element, containerWidth) {
      let min = 5
      let max = this.maxInitialFontSize
      let bestFit = min

      element.style.display = 'inline-block'
      element.style.whiteSpace = 'nowrap'
      element.style.lineHeight = this.computedLineHeight // Gebruik lineHeight hier

      while (min <= max) {
        const mid = Math.floor((min + max) / 2)
        element.style.fontSize = mid + 'px'

        const textWidth = element.scrollWidth
        if (textWidth <= containerWidth) {
          bestFit = mid
          min = mid + 1
        } else {
          max = mid - 1
        }
      }

      element.style.fontSize = bestFit + 'px'
      element.style.marginBottom = '10px'
    }
  }
}
</script>