<template>
  <div class="briefing_wizard__holder">

    <BriefingForm ref="briefingForm" :show="step == 1" :campaign_briefing="campaign_briefing"
      :on_finish="briefing_form_finished" :brand_homepage="brand_homepage" @validation="updateFormValidation" />

    <Transition name="fade">
      <span style="display: flex; flex: 1;" v-if="step == 2">

        <div class=briefing_wizard__body>
          <div class="briefing_wizard__main">

            <div class="briefing_wizard__header">
              <a href="/briefings" class="button__toggle__backplated">
                <i class="fa-solid fa-chevrons-left"></i>
                <span class="tooltip tooltip--bottom">Back</span>
              </a>
              <h4>{{ campaign_briefing.name }}</h4>
            </div>

            <div class="briefing_wizard__output" ref="outputContainer">
              <BriefingIteration v-for="(iteration, index) in iterations" :key="iteration.iteration_id"
                :campaign_briefing="campaign_briefing" :iteration="iteration" :active_size="active_size"
                :brandkit="brandkit" :show_previews="show_players" :active_ad="active_briefing"
                :on_active_ad_change="set_active_briefing" :on_commands_change="on_commands_change"
                :last="index === iterations.length - 1" :ref="`iteration_${iteration.uuid}`"
                :hasActiveIterationAbove="hasActiveIterationAbove(index)" />
            </div>

            <div class="briefing_wizard__prompt_nav_holder">
              <div class="briefing_wizard__prompt_nav">
                <div class="briefing_wizard__prompt_nav__labels">
                  <span style="display: flex;" v-for="(command_composition, index) in active_commands"
                    :key="`command_${index}`">
                    <label :key="`command_${index}_${ci}`" v-for="(command, ci) in command_composition.commands">
                      {{ command.pretty_name }}
                    </label>
                  </span>
                </div>
                <div class="button__group">
                  <button class="button--light" v-if="!generating" @click="openBriefingForm">Edit briefing</button>
                  <button v-if="!generating" class="button--gradient" @click="generate_ads" :disabled="!isFormValid">
                    Generate ads
                  </button>
                  <button v-else class="button--gradient">
                    <div class="loader loader--small"></div>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </span>
    </Transition>

    <!-- Add backdrop with v-show -->
    <div v-show="show_studio || loading_studio" class="studio-backdrop"
      :class="{ 'studio-backdrop--active': show_studio || loading_studio }"></div>

    <!-- Add loading state -->
    <Transition name="fade">
      <div v-if="loading_studio" class="studio-loading">
        <div class="studio-loading__spinner"></div>
        <div class="studio-loading__text">Opening Studio...</div>
      </div>
    </Transition>

    <!-- Studio transition -->
    <Transition name="studio-transition">
      <div v-if="show_studio" class="studio-container">
        <StudioV2 :initial_credits="credits" :briefing="active_briefing" :brandkit="brandkit" :fonts="fonts"
          :on_back="close_studio" :version="active_briefing.version" ref="studio" />
      </div>
    </Transition>

  </div>
</template>

<script>
import api from '../studio/api.js'
import studioApi from '../studio/store/api.js'
import CompactPlayer from '../studio/components/compact_player.vue'
import consumer from '../channels/consumer'
import Video from '../studio/components/video.vue'
import BriefingForm from './briefing_form.vue'
import BriefingIteration from './briefing_iteration.vue'
import StudioV2 from '../studio/studiov2.vue'
import { VueInfiniteViewer } from "vue-infinite-viewer";
import CanvasThumb from '../image-editor/canvas_thumb.vue'

export default {
  props: {
    brandkit: { type: Object, required: true },
    size: { type: Object, required: true },
    fonts: { type: Array, required: false, default: () => [] },
    all_available_sizes: { type: Array, required: true },
    campaign_briefing: { type: Object, required: true },
    brand_homepage: { type: String, required: false, default: false },
    credits: { type: Number, required: false, default: 0 }
  },
  components: { CompactPlayer, Video, BriefingForm, BriefingIteration, StudioV2, VueInfiniteViewer, CanvasThumb },
  data() {
    return {
      step: 1,
      asset_mode: null,
      suggestions_batch_progress: 0,
      suggestions: this.campaign_briefing.suggestions || [],
      suggestions_filter: 'best_suited',
      goal: this.campaign_briefing.goal || 'product promotion',
      media_lib_active: false,
      cb: this.campaign_briefing,
      generating: false,
      show_studio: false,
      active_briefing: null,
      active_briefing_loading: false,
      show_aside: false,
      expected: 8,
      current: 0,
      status_text: '',
      language: localStorage.getItem('selectedLanguage') || 'english (uk)', // haal de taal op of gebruik standaard
      text_gen: 'manual',
      feed: this.campaign_briefing.feed,
      active_size: null,
      show_players: false,
      preview_zoom: 0.6,
      flow: null,
      active_commands: [],
      l_iterations: [],
      bottom_margins: {
        'facebook_catalog': 10,
        'instagram_story': 20,
        'facebook_post': -50
      },
      isFormValid: true,
      loading_studio: false,
    }
  },
  computed: {
    available_sizes() {
      return this.cb.sizes.map(s => this.all_available_sizes.find(as => as.name == s))
    },
    iterations() {
      return this.l_iterations
    },
    assets() {
      return this.feed.data
    },
    videos() {
      return this.suggestions
    },
  },
  async mounted() {
    window.addEventListener("resize", this.set_zoom_level)
    this.cb = this.$props.campaign_briefing
    this.set_active_size(this.available_sizes[0])
    this.socket = this.attach_socket()
    this.l_iterations = this.campaign_briefing.iterations
    if (this.campaign_briefing.iterations.length > 0 || this.assets.length > 4) {
      this.step = 2
    }
    await this.$nextTick()
    this.scroll_to_bottom()
  },
  destroyed() {
    window.removeEventListener("resize", this.set_zoom_level)
    this.socket.unsubscribe()
  },
  watch: {
    language(newLanguage) {
      localStorage.setItem('selectedLanguage', newLanguage); // sla de taal op bij wijziging
    },
    step: {
      handler(newStep) {
        if (newStep === 2) {
          this.$nextTick(() => {
            this.scroll_to_bottom()
          })
        }
      }
    },
  },
  methods: {
    async preview_complete() {

    },
    async close_studio({ briefing_canvasses }) {
      this.show_studio = false
      let active_briefing = this.active_briefing
      this.active_briefing = null
      await this.$nextTick()
      setTimeout(() => {
        this.active_briefing = active_briefing
        this.$refs[`iteration_${active_briefing.iteration_id}`][0]?.force_update(this.active_briefing)
      }, 250)
    },
    set_zoom_level() {
      if (this.active_size && this.$refs.briefing_wizard_asside_body) {
        let width = this.$refs.briefing_wizard_asside_body.offsetWidth;
        let height = this.$refs.briefing_wizard_asside_body.offsetHeight;
        let zoom = Math.min(width / this.active_size.display_dimensions[0], height / this.active_size.display_dimensions[1]) * .86
        this.preview_zoom = zoom;
      }
    },
    on_commands_change(command_composition) {
      let found = !!this.active_commands.find(ac => ac.iteration_id == command_composition.iteration_id)
      if (found) {
        this.active_commands = this.active_commands.map(ac => {
          if (ac.iteration_id == command_composition.iteration_id) {
            return command_composition
          }
          return ac
        })
      } else {
        this.iterations.forEach(iteration => {
          if (iteration.uuid !== command_composition.iteration_id)
            this.$refs[`iteration_${iteration.uuid}`][0]?.clear_selection()
          this.active_commands = this.active_commands.filter(ac => ac.iteration_id !== iteration.uuid)
        })
        this.active_commands.push(command_composition)

        this.$nextTick(() => {
          if (this.iterations.findIndex(i => {
            return i.uuid == command_composition.iteration_id
          }) === this.iterations.length - 1)
            this.scroll_to_bottom()
        })

      }
    },
    async generate_ads(amount = 4) {
      if (!this.$refs.briefingForm?.validate()) {
        return // Don't generate if form isn't valid
      }

      this.generating = true
      const result = await api.generate_ads(this.cb, this.active_commands, amount)

      // Create iteration object with commands
      let iteration_obj = {
        uuid: result.iteration_id,
        ads: [],
        commands: this.active_commands.length > 0 ?
          this.active_commands[0].commands : [] // Add commands from active_commands
      }

      // Add placeholder ads
      for (let i = 0; i < result.n_to_expect; i++) {
        iteration_obj.ads.push({
          iteration_id: result.iteration_id,
          generating: true
        })
      }

      this.l_iterations.push(iteration_obj)
      this.active_commands = [] // Clear active commands after use

      await this.$nextTick()
      this.scroll_to_bottom()
    },
    async briefing_form_finished(campaign_briefing, generate_ads = true) {
      if (campaign_briefing) {
        this.cb = campaign_briefing
      }
      this.step = 2
      if (generate_ads) {
        if (this.iterations.length === 0) {
          this.generate_ads(6)
        } else {
          this.generate_ads(4)
        }
      }
    },
    attach_socket() {
      let self = this
      return consumer.subscriptions.create({
        channel: 'CampaignBriefingChannel',
        id: this.campaign_briefing.id
      }, {
        received(data) {
          if (data.event === 'generation') {
            self.current = data.current_n
            self.expected = data.total_n
            self.cb.sizes = data.sizes
            self.active_size = self.all_available_sizes.find(as => as.name == data.sizes[0])
            self.update_or_create_iteration_ad(data)
            self.suggestions_batch_progress += 1
            if (self.suggestions_batch_progress == self.expected) {
              self.status_text = 'Finishing up...'
              setTimeout(() => {
                self.generating = false
                self.suggestions_batch_progress = 0
              }, 600)
            }
          }
        }
      })
    },
    set_active_size(set_active_size) {
      this.active_size = set_active_size
      this.show_players = false
      setTimeout(() => {
        this.show_players = true
      }, 5)
    },
    async set_active_briefing(briefing) {
      try {
        this.loading_studio = true;
        const result = await studioApi.get_briefing(briefing);

        // Short delay to ensure smooth transition
        await new Promise(resolve => setTimeout(resolve, 300));

        this.active_briefing = result;
        this.show_studio = true;
        setTimeout(() => {
          this.$refs.studio.set_tab('components')
          this.$refs.studio.set_size('instagram_portrait')
        }, 300)
      } finally {
        this.loading_studio = false;
      }
    },
    async edit(briefing_id) {
      this.show_studio = true
    },
    update_or_create_iteration_ad(ad) {
      let iteration_obj = this.l_iterations.find(i => i.uuid == ad.iteration_id)
      let found = false
      iteration_obj.ads = iteration_obj.ads.map(a => {
        if (a.generating === true && !found) {
          found = true
          return { ...ad, generating: false, id: ad.video_briefing_id }
        }
        return a
      })
      this.l_iterations = [...this.l_iterations.map(i => {
        if (i.uuid == ad.iteration_id) {
          return iteration_obj
        }
        return i
      })]
    },
    async save() {
      this.current = 0
      if (this.generating === false) {
        this.generating = true
        this.status_text = `Generating videos... ${this.suggestions_batch_progress} / ${this.expected}`
        this.$modal.show('status_modal')
        await api.update_campaign_briefing({
          id: this.cb.id,
          name: this.cb.name,
          purpose: this.cb.purpose,
          goal: this.goal,
          language: this.language,
          text_gen: this.text_gen,
        })
      }
    },
    scroll_to_bottom() {
      const container = this.$refs.outputContainer;
      if (container) {
        container.scrollTo({
          top: container.scrollHeight,
          behavior: 'smooth'
        });
      }
    },
    openBriefingForm() {
      this.step = 1
      this.$nextTick(() => {
        if (this.$refs.briefingForm) {
          this.$refs.briefingForm.initializeExistingBriefing()
        }
      })
    },
    updateFormValidation(isValid) {
      this.isFormValid = isValid
    },
    hasActiveIterationAbove(currentIndex) {
      for (let i = 0; i < currentIndex; i++) {
        const iterationRef = this.$refs[`iteration_${this.iterations[i].uuid}`];
        if (iterationRef && iterationRef[0] && iterationRef[0].selected_version) {
          return true;
        }
      }
      return false;
    }
  }
}
</script>
