<template>
  <div :style="{
    width: layer.width + 'px',
    height: layer.height + 'px',
    position: 'relative',
    backgroundColor: 'none'
  }">
    <!-- Grid Container -->
    <div :style="gridContainerStyle">
      <!-- Render images dynamically -->
      <div 
        v-for="(img, index) in images" 
        :key="index" 
        :style="getImageStyle(index, img)"
      ></div>
    </div>
  </div>
</template>

<script>
import FontMixin from '../mixins/font';

export default {
  props: ['layer', 'animation_uid', 'scale'],
  mixins: [FontMixin],
  computed: {
    componentConfig() {
      return this.layer.config.image_grid;
    },
    numberOfRows() {
      return this.componentConfig.number_of_rows || 1;
    },
    images() {
      const config = this.layer.config.image_grid;
      return Object.keys(config.images)
        .slice(0, this.componentConfig.number_of_images)
        .map(key => config.images[key]);
    },
    gridContainerStyle() {
      const direction = this.componentConfig?.direction || 'row';
      const theGap = this.componentConfig?.gap + 'px';
      return {
        display: 'flex',
        flexDirection: direction,
        flexWrap: 'wrap',
        gap: theGap,
        width: '100%',
        height: '100%',
        boxSizing: 'border-box',
      };
    },
    // Bereken dynamisch het aantal rijen en kolommen op basis van direction
    rowCount() {
      if (this.images.length === 0) return 0;

      if (this.componentConfig.direction === 'row') {
        // Standaard logic: number_of_rows is aantal rijen
        return this.numberOfRows;
      } else {
        // Bij column: number_of_rows interpreteren als aantal kolommen
        // We berekenen hier het aantal rijen door images.length te delen door deze 'kolommen'
        return Math.ceil(this.images.length / this.numberOfRows);
      }
    },
    columnCount() {
      if (this.images.length === 0) return 0;

      if (this.componentConfig.direction === 'row') {
        // Standaard logic voor row: aantal kolommen = ceil(aantal_images / aantal_rijen)
        return Math.ceil(this.images.length / this.numberOfRows);
      } else {
        // Bij column: number_of_rows wordt als kolommen gezien
        return this.numberOfRows;
      }
    },
    theGap() {
      return (this.componentConfig?.gap || 0);
    }
  },
  methods: {
    cleanSource(source) {
      if (source) {
        try {
          const url = new URL(source);
          const focus_x = url.searchParams.get('focus_x');
          const focus_y = url.searchParams.get('focus_y');
          if (focus_x && focus_y && url.searchParams.toString() === `focus_x=${focus_x}&focus_y=${focus_y}`) {
            return url.origin + url.pathname;
          }
          return source.split('?').shift();
        } catch {
          return source;
        }
      }
      return source
    },
    getFocusPoints(image) {
      try {
        const url = new URL(image);
        const focusX = parseFloat(url.searchParams.get('focus_x') || 0);
        const focusY = parseFloat(url.searchParams.get('focus_y') || 0);
        return { x: focusX, y: focusY };
      } catch {
        return { x: 0, y: 0 };
      }
    },
    getBackgroundPosition(image) {
      if (this.componentConfig.scale === 'contain') {
        return 'center';
      }

      const focus = this.getFocusPoints(image);
      let y = Math.abs(focus.y) / 2 * 100;
      let x = Math.abs(focus.x) / 2 * 100;

      if (focus.y < 0) {
        y += 50;
      } else {
        y = 50 - Math.abs(focus.y) * 50;
      }

      if (focus.x > 0) {
        x += 50;
      } else {
        x = 50 - Math.abs(focus.x) * 50;
      }

      return `${x}% ${y}%`;
    },
    getImageStyle(index, img) {
      const backgroundSize = this.componentConfig.scale || 'cover';
      const bgColor = this.componentConfig.show_bg ? (this.componentConfig.color_1 || '#111111') : 'transparent';
      const direction = this.componentConfig.direction || 'row';

      const style = {
        backgroundSize: backgroundSize,
        backgroundRepeat: 'no-repeat',
        backgroundPosition: this.getBackgroundPosition(img),
        backgroundColor: bgColor,
        borderRadius: this.componentConfig.border_radius + 'px',
        overflow: 'hidden',
        backgroundImage: `url(${this.cleanSource(img)})`,
      };

      const totalGapHorizontal = (this.columnCount - 1) * this.theGap;
      const totalGapVertical = (this.rowCount - 1) * this.theGap;

      const widthPerItem = `calc((100% - ${totalGapHorizontal}px) / ${this.columnCount})`;
      const heightPerItem = `calc((100% - ${totalGapVertical}px) / ${this.rowCount})`;

      // Afhankelijk van de richting pas je width/height toe
      if (direction === 'row') {
        style.flexGrow = 0;
        style.flexBasis = widthPerItem;
        style.height = heightPerItem;
      } else {
        // Bij direction='column' worden rows en columns omgedraaid zoals gevraagd:
        // We hebben al rowCount en columnCount opnieuw gedefinieerd dus
        // we kunnen dezelfde berekeningen gebruiken.
        style.flexGrow = 0;
        style.width = widthPerItem;
        style.height = heightPerItem;
      }

      return style;
    }
  }
};
</script>