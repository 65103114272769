import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class ImageGrid extends Component {
  constructor({ opts } = { opts: {} }) {
    super()
    this.opts = opts
  }

  name() {
    return 'Image grid'
  }

  labels() {
    return ['grid']
  }

  icon() {
    return 'fa-solid fa-heart'
  }

  sizeConfig(size) {
    if (size) {
      switch (size.aspect_ratio) {
        case '1:1':
          return {
            config: {
              image_grid: {
                direction: 'row',
              }
            }
          }
        case '16:9':
          return {
            config: {
              image_grid: {
                direction: 'row',
              }
            }
          }
        case '9:16':
          return {
            config: {
              image_grid: {
                direction: 'row',
              }
            }
          }
        default:
          return {}
      }
    }
    return {}
  }

  configFields() {
    return {
      name: this.name(),
      options: [
        {
          type: 'slider',
          name: 'Number of rows',
          key: 'number_of_rows',
          min: 1,
          max: 9,
          step: 1,
        },
        {
          type: 'slider',
          name: 'Number of images',
          key: 'number_of_images',
          min: 2,
          max: 9,
          step: 1,
          transformers: [
            (key, value, layer) => {
              const l = { ...layer }
              for (let i = 0; i < value; i += 1) {
                const k = i.toString()
                if (!l.config.image_grid.images[k]) {
                  l.config.image_grid.images[k] = l.config.image_grid.default_image
                }
              }
              return l
            }
          ]
        },
        {
          type: 'multiAssetPicker',
          name: 'Images',
          key: 'images',
          number_of_assets_key: 'number_of_images',
        },
        {
          type: 'buttonGroup',
          name: 'Scale',
          key: 'scale',
          options: [
            {
              type: 'text',
              name: 'Fit',
              icon: 'fa-regular fa-left',
              value: 'contain',
            },
            {
              type: 'text',
              name: 'Cover',
              icon: 'fa-regular fa-right',
              value: 'cover',
            },
          ]
        },
        {
          type: 'buttonGroup',
          name: 'Direction',
          hide_label: false,
          key: 'direction',
          transformers: [
            (key, value, layer) => {
              if (key === 'direction' && value === 'column') {
                const { height } = layer
                const { width } = layer
                const l = { ...l }
                l.width = height
                l.height = width
                return l
              } if (key === 'direction' && value === 'row') {
                const { height } = layer
                const { width } = layer
                const l = { ...l }
                l.width = height
                l.height = width
                return l
              }
              return layer
            }
          ],
          options: [
            {
              type: 'icon',
              name: 'Right',
              icon: 'fa-regular fa-arrow-right',
              value: 'row',
            },
            {
              type: 'icon',
              name: 'Down',
              icon: 'fa-regular fa-arrow-down',
              value: 'column',
            }
          ]
        },
        {
          type: 'slider',
          name: 'Gap',
          key: 'gap',
          min: 0,
          max: 100,
          step: 1,
        },
        {
          type: 'slider',
          name: 'Rounded corners',
          key: 'border_radius',
          min: 0,
          max: 300,
          step: 1,
        },
        [
          {
            type: 'color',
            name: 'Background color',
            key: 'color_1',
          },
          {
            type: 'toggle',
            name: 'Show background',
            key: 'show_bg',
          },
        ],
      ],
    }
  }

  variants() {
    const baseConfig = this.baseConfig()

    return [
      {
        background: '#E1E3E5',
        layers: [{
          ...baseConfig,
          config: {
            ...baseConfig.config,
            image_grid: {
              ...baseConfig.config.image_grid,
              default_image: 'https://cdn.adflow.io/public/9bef15f1-17c5-4de2-8cf8-9291f21b4062.png',
              number_of_images: 3,
              images: {
                0: 'https://cdn.adflow.io/public/9bef15f1-17c5-4de2-8cf8-9291f21b4062.png',
                1: 'https://cdn.adflow.io/public/9bef15f1-17c5-4de2-8cf8-9291f21b4062.png',
                2: 'https://cdn.adflow.io/public/9bef15f1-17c5-4de2-8cf8-9291f21b4062.png',
              },
            }
          },
        }]
      },
      {
        background: '#E1E3E5',
        layers: [{
          ...baseConfig,
          width: 1046,
          height: 1046,
          config: {
            ...baseConfig.config,
            image_grid: {
              ...baseConfig.config.image_grid,
              default_image: 'https://cdn.adflow.io/public/9bef15f1-17c5-4de2-8cf8-9291f21b4062.png',
              number_of_images: 9,
              number_of_rows: 3,
              images: {
                0: 'https://cdn.adflow.io/public/9bef15f1-17c5-4de2-8cf8-9291f21b4062.png',
                1: 'https://cdn.adflow.io/public/9bef15f1-17c5-4de2-8cf8-9291f21b4062.png',
                2: 'https://cdn.adflow.io/public/9bef15f1-17c5-4de2-8cf8-9291f21b4062.png',
                3: 'https://cdn.adflow.io/public/9bef15f1-17c5-4de2-8cf8-9291f21b4062.png',
                4: 'https://cdn.adflow.io/public/9bef15f1-17c5-4de2-8cf8-9291f21b4062.png',
                5: 'https://cdn.adflow.io/public/9bef15f1-17c5-4de2-8cf8-9291f21b4062.png',
                6: 'https://cdn.adflow.io/public/9bef15f1-17c5-4de2-8cf8-9291f21b4062.png',
                7: 'https://cdn.adflow.io/public/9bef15f1-17c5-4de2-8cf8-9291f21b4062.png',
                8: 'https://cdn.adflow.io/public/9bef15f1-17c5-4de2-8cf8-9291f21b4062.png',
              },
            }
          },
        }]
      },
    ]
  }

  baseConfig() {
    return {
      layer_type: 'component',
      width: 1046,
      height: 260,
      ...this.opts,
      unlinked_properties: ['component.direction'],
      config: {
        component: {
          type: 'image_grid',
          name: 'Image grid',
        },
        image_grid: {
          color_1: '#F3F3F3',
          number_of_rows: 1,
          direction: 'row',
          image_size: 0,
          gap: 10,
          default_image: 'https://cdn.adflow.io/public/9bef15f1-17c5-4de2-8cf8-9291f21b4062.png',
          images: {
            0: 'https://cdn.adflow.io/public/9bef15f1-17c5-4de2-8cf8-9291f21b4062.png',
            1: 'https://cdn.adflow.io/public/9bef15f1-17c5-4de2-8cf8-9291f21b4062.png',
          },
          scale: 'contain',
          number_of_images: 2,
          border_radius: 0,
          show_bg: true
        }
      }
    }
  }

  config() {
    return {
      background: '#E1E3E5',
      layers: [
        Layer.factory(this.baseConfig())
      ]
    }
  }
}
