var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{style:({ color: _vm.layer.color, width: _vm.layer.width + 'px', position: 'relative' })},[_c('svg',{staticStyle:{"position":"absolute","top":"50%","left":"50%","transform":"translate(-50%, -50%)","z-index":"-1"},attrs:{"xmlns":"http://www.w3.org/2000/svg","width":_vm.layer.width,"height":_vm.layer.height,"fill":"none","viewBox":"0 0 54 54"}},[_c('path',{attrs:{"fill":_vm.layer.config.sticker_pricetag_circle.bg_color,"fill-opacity":_vm.layer.config.sticker_pricetag_circle.show_bg ? 1 : 0,"stroke":_vm.layer.config.sticker_pricetag_circle.show_border ? _vm.layer.config.sticker_pricetag_circle.bg_border_color : 'none',"stroke-width":_vm.layer.config.sticker_pricetag_circle.border_width / 10,"d":_vm.shapePath,"transform":_vm.shapeTransform}})]),_vm._v(" "),_c('div',{style:(("\n            display: flex;\n            flex-direction: column;\n            z-index: 0;\n            gap: " + (_vm.layer.config.sticker_pricetag_circle.gap) + "px;\n        "))},[_c('span',{style:({ 
                color: _vm.layer.config.sticker_pricetag_circle.top_text_color, 
                fontSize: _vm.layer.fontSize * 0.16 + 'px',
                textDecoration: _vm.layer.config.sticker_pricetag_circle.line_through ? 'line-through' : 'none',
                fontFamily: _vm.font,
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
            }),domProps:{"innerHTML":_vm._s(_vm.top_textWithBreaks)}}),_vm._v(" "),_c('div',{style:(("\n                display: flex;\n                flex-direction: " + (_vm.layer.config.sticker_pricetag_circle.h_align) + ";\n                align-items: " + (_vm.layer.config.sticker_pricetag_circle.v_align) + ";\n                justify-content: center;"))},[_c('span',{style:(("\n                    color: " + (_vm.layer.color) + ";\n                    font-size: " + (_vm.layer.fontSize * 0.55) + "px;\n                    font-family: " + _vm.font + ";\n                    white-space: normal;\n                    word-wrap: break-word; \n                    overflow-wrap: break-word;")),domProps:{"innerHTML":_vm._s(_vm.textWithBreaks)}}),_vm._v(" "),_c('span',{style:({ 
                    color: _vm.layer.config.sticker_pricetag_circle.label_color, 
                    fontSize: _vm.layer.fontSize * 0.25 + 'px',
                    fontFamily: _vm.font,
                })},[_vm._v("\n                "+_vm._s(_vm.label)+"\n            ")])]),_vm._v(" "),_c('span',{style:({ 
                color: _vm.layer.config.sticker_pricetag_circle.bottom_text_color, 
                fontSize: _vm.layer.fontSize * 0.16 + 'px',
                fontFamily: _vm.font,
                whiteSpace: 'normal',
                wordWrap: 'break-word',
                overflowWrap: 'break-word',
            }),domProps:{"innerHTML":_vm._s(_vm.bottom_textWithBreaks)}})])])}
var staticRenderFns = []
export { render, staticRenderFns }