import Component from '../component'
import Layer from '../../store/modules/layers/model'

export default class ImageOutlineEffect extends Component {
  constructor({ opts } = { opts: {} }) {
    super()
    this.opts = opts
  }

  name() {
    return 'Image Outline Effect'
  }

  labels() {
    return ['sticker']
  }

  icon() {
    return 'fa-solid fa-heart'
  }

  configFields() {
    return {
      name: this.name(),
      options: [
        {
          type: 'assetPicker',
          name: 'Image',
          key: 'image',
        },
        {
          type: 'color',
          name: 'Border color',
          key: 'line_color',
        },
        {
          type: 'slider',
          name: 'Border width',
          key: 'line_width',
          min: 0,
          max: 100,
          step: 1,
        },
      ],
    }
  }

  variants() {
    const baseConfig = this.baseConfig()

    return [
      {
        background: '#E1E3E5',
        layers: [{
          ...baseConfig,
          config: {
            ...baseConfig.config,
            image_outline_effect: {
              ...baseConfig.config.image_outline_effect,
              line_color: '#ffffff',
            }
          },
        }]
      },
    ]
  }

  baseConfig() {
    return {
      layer_type: 'component',
      width: 600,
      height: 600,
      ...this.opts,
      config: {
        component: {
          type: 'image_outline_effect',
          name: 'Outline image',
        },
        image_outline_effect: {
          line_color: '#000000',
          line_width: 12,
          image: 'https://cdn.adflow.io/public/9bef15f1-17c5-4de2-8cf8-9291f21b4062.png',
        }
      }
    }
  }

  config() {
    return {
      background: '#E1E3E5',
      layers: [
        Layer.factory(this.baseConfig())
      ]
    }
  }
}
