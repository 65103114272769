// MarkerList.vue
<template>
    <div class="marker-list">
        <h3>Captured Markers</h3>
        <div class="marker-item" v-for="marker in markers" :key="marker.time">
            <div class="thumbnail" :style="{ backgroundImage: `url(${marker.thumbnail})` }"></div>
            <div class="time">{{ formatTime(marker.time) }}</div>
            <button @click="$emit('delete-marker', marker)" aria-label="Delete Marker">🗑️</button>
        </div>
    </div>
</template>

<script>
export default {
    name: "MarkerList",
    props: {
        markers: {
            type: Array,
            required: true,
        },
    },
    methods: {
        formatTime(seconds) {
            const minutes = Math.floor(seconds / 60);
            const secs = Math.floor(seconds % 60).toString().padStart(2, "0");
            return `${minutes}:${secs}`;
        },
    },
};
</script>